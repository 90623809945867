/**********************************************************/
/*    Typography
/**********************************************************/
@font-face {
  font-family: "Montserrat-Light";
  src: url("^assets/fonts/Montserrat-Light.ttf");
}

@font-face {
  font-family: "Montserrat-Medium";
  src: url("^assets/fonts/Montserrat-Medium.ttf");
}

@font-face {
  font-family: "Montserrat-Regular";
  src: url("^assets/fonts/Montserrat-Regular.ttf");
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("^assets/fonts/Montserrat-SemiBold.ttf");
}


@font-face {
  font-family: "LibreFranklin-Light";
  src: url("^assets/fonts/LibreFranklin-Light.ttf");
}

@font-face {
  font-family: "LibreFranklin-Medium";
  src: url("^assets/fonts/LibreFranklin-Medium.ttf");
}

@font-face {
  font-family: "LibreFranklin-Regular";
  src: url("^assets/fonts/LibreFranklin-Regular.ttf");
}

@font-face {
  font-family: "LibreFranklin-Bold";
  src: url("^assets/fonts/LibreFranklin-Bold.ttf");
}

@font-face {
  font-family: "LibreFranklin-SemiBold";
  src: url("^assets/fonts/LibreFranklin-SemiBold.ttf");
}

@font-face {
  font-family: "Arial-Regular";
  src: url("^assets/fonts/ARIAL.ttf");
}

@font-face {
  font-family: "Arial-Medium";
  src: url("^assets/fonts/ArialMdm.ttf");
}

@font-face {
  font-family: "Arial-Bold";
  src: url("^assets/fonts/ARIALBD.ttf");
}

@font-face {
  font-family: "icomoon";
  src: url("^assets/fonts/icomoon.eot");
  src: url("^assets/fonts/icomoon.eot") format("embedded-opentype"), url("^assets/fonts/icomoon.ttf") format("truetype"),
    url("^assets/fonts/icomoon.woff") format("woff"), url("^assets/fonts/icomoon.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "icomoon";
  src: url("^assets/fonts/icomoon.ttf");
}

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: local("Material Icons"), local("MaterialIcons-Regular"), url("^assets/fonts/Mate-Icon.woff2") format("woff2");
}
