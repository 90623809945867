.arrow-top-23:after {
  position: absolute;
  box-sizing: border-box;
  display: block;
  width: 15px;
  height: 15px;
  -webkit-box-shadow: -1px -1px 2px 0px rgba(51, 51, 51, 0.1);
  box-shadow: -1px -1px 2px 0px rgba(51, 51, 51, 0.1);
  top: -7px;
  right: 23.5px;
  margin-left: 0;
  content: "";
  margin-top: 0;
  background-color: white;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.arrow-top-12:after {
  position: absolute;
  box-sizing: border-box;
  display: block;
  width: 15px;
  height: 15px;
  -webkit-box-shadow: -1px -1px 2px 0px rgba(51, 51, 51, 0.1);
  box-shadow: -1px -1px 2px 0px rgba(51, 51, 51, 0.1);
  top: -7px;
  right: 12.5px;
  margin-left: 0;
  content: "";
  margin-top: 0;
  background-color: white;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.overlay-popup.arrow-top-right,
.overlay-popup.arrow-bottom-right,
.overlay-popup.arrow-top-left,
.overlay-popup.arrow-bottom-left,
.overlay-popup.arrow-top-centre,
.overlay-popup.arrow-bottom-centre {
  margin: 1em 0em;
}

.arrow-top-centre,
.arrow-bottom-centre {
  margin: 1em 0em;
}

.arrow:after {
  position: absolute;
  box-sizing: border-box;
  display: block;
  width: 15px;
  height: 15px;
  -webkit-box-shadow: -1px -1px 2px 0px rgba(51, 51, 51, 0.1);
  box-shadow: -1px -1px 2px 0px rgba(51, 51, 51, 0.1);
  margin-left: 0;
  content: "";
  margin-top: 0;
  background-color: white;
  z-index: -1;
}

.arrow-top-right:after {
  top: -7px;
  right: 23px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.arrow-bottom-right:after {
  bottom: -7px;
  right: 23px;
  transform: rotate(225deg);
  -webkit-transform: rotate(225deg);
}

.arrow-top-left:after {
  top: -7px;
  left: 23px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.arrow-bottom-left:after {
  bottom: -7px;
  left: 23px;
  transform: rotate(225deg);
  -webkit-transform: rotate(225deg);
}

.arrow-top-centre:after {
  top: -7px;
  left: 50%;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.arrow-bottom-centre:after {
  bottom: -7px;
  left: 50%;
  transform: rotate(225deg);
  -webkit-transform: rotate(225deg);
}

.dark-theme {
  .arrow-top-23:after,
  .arrow-top-12:after,
  .arrow:after {
    background-color: rgb($secondary-background-color);
  }
}
