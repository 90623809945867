/**********************************************************/
/*    Colour Palette
/*****************************************************************/
:root {
  --base-color-white: 255, 255, 255; //#ffffff
  --primary-color-regular: 0, 56, 131; //#003883
  --primary-color-light: 51, 96, 156; //#33609c
  --primary-color-dark: 0, 45, 105; //#002d69
  --secondary-color-regular: 238, 125, 17; //#EE7D11
  --secondary-color-light: 242, 151, 65; //#f29741
  --secondary-color-dark: 191, 100, 14; //#bf640e
  --grey-shade-dark: 51, 51, 51; //#333333
  --grey-shade-medium: 102, 102, 102; //#666666
  --grey-shade-light: 117, 117, 117; //#757575
  --error-color: 195, 49, 73; //#C33149
  --success-color: 36, 161, 72; //#24A148
  --warning-color: 255, 191, 70; //#FFBF46
  --link-color: 1, 151, 244; //#0197f4
  --shadow-color: 51, 51, 51; //#333333
  --table-header-color: 255, 255, 255; //#eaecf5
  --table-row-color-1: 255, 255, 255; //#f9f9fc
  --table-row-color-2: 255, 255, 255; //#ffffff
  --table-row-hover-color: 246,247,250; //#eef0f7 //246,247,250
  --table-row-select-color: 246,247,250; //#eef0f7 //246,247,250
  --page-background-color: 255, 255, 255; //#ffffff
  --secondary-page-background: 244, 244, 244; //#f4f4f4
  --light-theme-blue: 57, 83, 147; //#395393
  --secondary-color-lighter: 252, 234, 217; //#FCEAD9
  --selection-color: 0,0,0; //#000000
  --table-border-color: 0, 56, 131; //#003883
  
  //Toaster_Notification_Colors
  --toaster-primary: 204, 234, 253; //CCEAFD
  --toaster-warning: 255, 242, 218; //FFF2DA
  --toaster-success: 211, 236, 218; //D3ECDA
  --toaster-error: 243, 213, 218; //F3D5DA
  --stepper-green: 36, 209, 86; //#24d156
  --stepper-blue: 87, 110, 175; //#576EAF
}

.dark-theme {
  --base-color-white: 51, 51, 51; //#333333
  --grey-shade-dark: 242, 242, 242; //#f2f2f2
  --grey-shade-medium: 204, 204, 204; //#e6e6e6
  --grey-shade-light: 191, 191, 191; //#cccccc
  --error-color: 247, 62, 83; //#F73E53
  --success-color: 111, 214, 136; //#6FD688
  --warning-color: 255, 204, 1; //#FFCC01
  --link-color: 43, 163, 255; //#2ba3ff
  --shadow-color: 0, 0, 0; //#000000
  --table-header-color: 87, 87, 87; //#575757
  --table-row-color-1: 60, 60, 60; //#3c3c3c
  --table-row-color-2: 51, 51, 51; //#333333
  --table-row-hover-color: 78, 78, 78; //#4e4e4e
  --table-row-select-color: 78, 78, 78; //#4e4e4e
  --secondary-background-color: 66, 66, 66; //#424242
}

$toaster-primary : var(--toaster-primary);
$toaster-warning : var(--toaster-warning);
$toaster-success : var(--toaster-success);
$toaster-error : var(--toaster-error);

$shadow-color: var(--shadow-color);
$selection-color: var(--selection-color);
$base-color-white: var(--base-color-white);

$primary-color-regular: var(--primary-color-regular);
$primary-color-light: var(--primary-color-light);
$primary-color-dark: var(--primary-color-dark);

$secondary-color-regular: var(--secondary-color-regular);
$secondary-color-light: var(--secondary-color-light);
$secondary-color-dark: var(--secondary-color-dark);

$grey-shade-dark: var(--grey-shade-dark);
$grey-shade-medium: var(--grey-shade-medium);
$grey-shade-light: var(--grey-shade-light);

$error-color: var(--error-color);
$success-color: var(--success-color);
$warning-color: var(--warning-color);
$app-link-color: var(--link-color);
$table-border-color: var(--table-border-color);
$table-header-color: var(--table-header-color);
$table-row-color-1: var(--table-row-color-1);
$table-row-color-2: var(--table-row-color-2);
$table-row-hover-color: var(--table-row-hover-color);
$table-row-select-color: var(--table-row-select-color);

$page-background-color: var(--page-background-color);
$secondary-page-background: var(--secondary-page-background);
$light-theme-blue: var(--light-theme-blue);
$secondary-color-lighter: var(--secondary-color-lighter);
$secondary-background-color: var(--secondary-background-color);
$stepper-green: var(--stepper-green);
$stepper-blue: var(--stepper-blue);