::-webkit-scrollbar {
  width: 15px;
  visibility: hidden;
}

:hover::-webkit-scrollbar-track {
  box-shadow: inset 0 0 15px 15px rgba($grey-shade-light, 0.5);
  border: solid 5px transparent;
  border-radius: 13px;
  visibility: visible;
}

:hover::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 15px 15px rgb($app-link-color);
  border: solid 5px transparent;
  border-radius: 13px;
  visibility: visible;
}

:hover::-webkit-scrollbar-button {
  height: 5px;
  display: block;
  background-color: transparent;
  visibility: visible;
}

mat-table::-webkit-scrollbar-track {
  margin-top: 50px;
}
