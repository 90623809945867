.mat-menu-tag-close {
  display: inline-block;
  text-align: center;
  font-size: 10px;
  position: relative;
  border-width: 1px;
  border-style: solid;
  border-radius: 50%;
  padding: 4px 2px;
  top: -15px;
  left: 7px;
  height: 16px;
  width: 16px;
  line-height: 8px;
  background-color: rgb($base-color-white);
  color: rgb($app-link-color);
}

.mat-menu-tag {
  background-color: rgba($primary-color-dark, 0.05);
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba($grey-shade-dark, 0.05);
  color: rgb($grey-shade-dark);
  font-size: 12px;
  padding: 5px;
}

.mat-menu-tag-tick {
  display: inline-block;
  text-align: center;
  position: relative;
  font-size: 7px;
  border-width: 1px;
  border-style: solid;
  border-radius: 50%;
  top: -2px;
  padding: 3px;
  height: 16px;
  width: 16px;
  line-height: 8px;
  background-color: rgb($base-color-white);
  color: rgb($app-link-color);
}

a {
  .mat-menu-tag-close {
    margin: -7px;
  }
}
