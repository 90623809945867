[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: icomoon, sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-size: $icon-font-size;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-color {
  color: rgb(var(--link-color));
  cursor: pointer;

  &+mat-icon {
    color: rgb($app-link-color);
  }
}

.icon-tick-checkbox:before {
  content: "\e900";
}

.icon-Add:before {
  content: "\e901";
}

.icon-dustbin:before {
  content: "\e902";
}

.icon-Down-arrow:before {
  content: "\e903";
}

.icon-Up-Arrow:before {
  content: "\e904";
}

.icon-UpArrow:before {
  content: "\e905";
}

.icon-UpArrow2:before {
  content: "\e906";
}

.icon-Storage:before {
  content: "\e907";
}

.icon-SampleReg:before {
  content: "\e908";
}

.icon-ChangeDate:before {
  content: "\e909";
}

.icon-Graph:before {
  content: "\e913";
}

.icon-Dispose:before {
  content: "\e90b";
}

.icon-con:before {
  content: "\e90c";
}

.icon-StorageLE:before {
  content: "\e90a";
}

.icon-StorageDesing:before {
  content: "\e90d";
}

.icon-Pre_defined_list:before {
  content: "\e90e";
}

.icon-Sorting_config:before {
  content: "\e90f";
}

.icon-Maintenance:before {
  content: "\e910";
}

.icon-Pre-Ana-Pro-Setup:before {
  content: "\e911";
}

.icon-Preferences:before {
  content: "\e912";
}

.icon-Structured:before {
  content: "\e983";
}

.icon-moveable:before {
  content: "\e98e";
}

.icon-flask:before {
  content: "\e98f";
}

.icon-Disposable:before {
  content: "\e990";
}

.icon-Disposable-Area:before {
  content: "\e991";
}

.icon-Risk-item:before {
  content: "\e992";
}

.icon-rush:before {
  content: "\e993";
}

.icon-PSRT:before {
  content: "\e994";
}

.icon-PISRT:before {
  content: "\e995";
}

.icon-search:before {
  content: "\e996";
}

.icon-printer:before {
  content: "\e997";
}

.icon-printer_settings:before {
  content: "\e998";
}

.icon-edit:before {
  content: "\e999";
}

.icon-info-button:before {
  content: "\e99a";
}

.icon-cold:before {
  content: "\e914";
}

.icon-Location:before {
  content: "\e915";
}

.icon-update:before {
  content: "\e916";
}

.icon-copy:before {
  content: "\e917";
}

.icon-Attributies:before {
  content: "\e918";
}

.icon-networking:before {
  content: "\e919";
}

.icon-report:before {
  content: "\e91a";
}

.icon-back:before {
  content: "\e91b";
}

.icon-close:before {
  content: "\e91c";
}

.icon-tick-thin:before {
  content: "\e91d";
}

.icon-Find-Template:before {
  content: "\e91e";
}

.icon-Site-map:before {
  content: "\e91f";
}

.icon-place-holder:before {
  content: "\e920";
}

.icon-Reset:before {
  content: "\e923";
}

.icon-user:before {
  content: "\e924";
}

.icon-CATALOG:before {
  content: "\e921";
}

.icon-Find-Catagory:before {
  content: "\e922";
}

.icon-chat:before {
  content: "\e925";
}

.icon-Expand:before {
  content: "\e926";
}

.icon-collapse:before {
  content: "\e927";
}

.icon-alert:before {
  content: "\e928";
}

.icon-more-info:before {
  content: "\e929";
}

.icon-sort:before {
  content: "\e92a";
}

.icon-filter:before {
  content: "\e92b";
}

.icon-refresh:before {
  content: "\e92c";
}

.icon-Partially-Approve:before {
  content: "\e92d";
}

.icon-ready-to-Approve:before {
  content: "\e92e";
}

.icon-Approved:before {
  content: "\e92f";
}

.icon-fillstar:before {
  content: "\e930";
}

.icon-star:before {
  content: "\e931";
}

.icon-pdf:before {
  content: "\e932";
}

.icon-excel:before {
  content: "\e933";
}

.icon-duedate:before {
  content: "\e934";
}

.icon-more:before {
  content: "\e935";
}

.icon-Forbidden:before {
  content: "\e936";
}

.icon-404:before {
  content: "\e937";
}

.icon-Internal-Server-Error:before {
  content: "\e938";
}

.icon-Unauthorized:before {
  content: "\e939";
}

.icon-Set-rule:before {
  content: "\e93a";
}

.icon-map-parameters:before {
  content: "\e93b";
}

.icon-export-report:before {
  content: "\e93c";
}

.icon-Inventory:before {
  content: "\e93d";
}

.icon-Reservation-Managment:before {
  content: "\e93e";
}

.icon-PAP:before {
  content: "\e93f";
}

.icon-inout:before {
  content: "\e940";
}

.icon-Storage-Overiew:before {
  content: "\e941";
}

.icon-Issues-Management:before {
  content: "\e942";
}

.icon-tracking:before {
  content: "\e943";
}

.icon-Move-Container:before {
  content: "\e944";
}

.icon-Add-Quotation-Extension:before {
  content: "\e945";
}

.icon-Find-Quotation-Extension:before {
  content: "\e946";
}

.icon-Quotation-Extension:before {
  content: "\e947";
}

.icon-chat-bubbles-with-ellipsis:before {
  content: "\e948";
}

.icon-Archive:before {
  content: "\e949";
}

.icon-Mail-Unread:before {
  content: "\e94a";
}

.icon-Mail-Open:before {
  content: "\e94b";
}

.icon-eraser:before {
  content: "\e94c";
}

.icon-Link:before {
  content: "\e94d";
}

.icon-Parcel-List:before {
  content: "\e94e";
}

.icon-settings:before {
  content: "\e94f";
}

.icon-Lab-Site:before {
  content: "\e950";
}

.icon-Value-Table:before {
  content: "\e951";
}

.icon-Driver-Management:before {
  content: "\e952";
}

.icon-Process--Instrument:before {
  content: "\e953";
}

.icon-Application-Settings:before {
  content: "\e954";
}

.icon-Method:before {
  content: "\e955";
}

.icon-Sequence-Creation:before {
  content: "\e956";
}

.icon-Production:before {
  content: "\e957";
}

.icon-checklist:before {
  content: "\e958";
}

.icon-Storage-Operation:before {
  content: "\e959";
}

.icon-Storage-management:before {
  content: "\e95a";
}

.icon-Global:before {
  content: "\e95b";
}

.icon-Unit:before {
  content: "\e95c";
}

.icon-Grid:before {
  content: "\e95d";
}

.icon-List:before {
  content: "\e95e";
}

.icon-Migration:before {
  content: "\e95f";
}

.icon-error:before {
  content: "\e960";
}

.icon-notification:before {
  content: "\e961";
}

.icon-processing:before {
  content: "\e962";
}

.icon-Account-OpAF-Group:before {
  content: "\e963";
}

.icon-Search-SAFG:before {
  content: "\e964";
}

.icon-Search-AOAFG:before {
  content: "\e965";
}

.icon-Search-AF:before {
  content: "\e966";
}

.icon-Sample-Additional-Field-Group:before {
  content: "\e967";
}

.icon-Additional-Field:before {
  content: "\e968";
}

.icon-Add-SAF-Group:before {
  content: "\e969";
}

.icon-Add-AO-SAF-Group:before {
  content: "\e96a";
}

.icon-Add-AF:before {
  content: "\e96b";
}

.icon-do-not-disturb:before {
  content: "\e96c";
}

.icon-Content-Placeholder:before {
  content: "\e96d";
}

.icon-Workstation:before {
  content: "\e96e";
}

.icon-Label-Template:before {
  content: "\e96f";
}

.icon-Generate-Print-Definition:before {
  content: "\e970";
}

.icon-Forbid-Disposal:before {
  content: "\e971";
}

.icon-Lock:before {
  content: "\e972";
}

.icon-Double-Tick:before {
  content: "\e973";
}

.icon-Attach:before {
  content: "\e974";
}

.icon-Lab-Repition:before {
  content: "\e975";
}

.icon-Apply-Rule:before {
  content: "\e976";
}

.icon-Filter-Applied:before {
  content: "\e977";
}

.icon-Break-point-rule:before {
  content: "\e978";
}

.icon-CAD-Security-Level:before {
  content: "\e979";
}

.icon-Chain-Management:before {
  content: "\e97a";
}

.icon-Device-Management:before {
  content: "\e97b";
}

.icon-Dynamic-Guidance:before {
  content: "\e97c";
}

.icon-Internal-Test:before {
  content: "\e97d";
}

.icon-Measurement-Template:before {
  content: "\e97e";
}

.icon-Processing-Permission-Groups:before {
  content: "\e97f";
}

.icon-QC-Item-Rule-Creation:before {
  content: "\e980";
}

.icon-QC-Item-validtiy-Group:before {
  content: "\e981";
}

.icon-Standard-Comments:before {
  content: "\e982";
}

.icon-Workstation-Context:before {
  content: "\e984";
}

.icon-Workstation1:before {
  content: "\e985";
}

.icon-Activites:before {
  content: "\e986";
}

.icon-Chain:before {
  content: "\e987";
}

.icon-Invalid-Chain:before {
  content: "\e988";
}

.icon-Lab-containers-Type:before {
  content: "\e989";
}

.icon-Label-Templates:before {
  content: "\e98a";
}

.icon-Plausibility:before {
  content: "\e98b";
}

.icon-Publications:before {
  content: "\e98c";
}

.icon-Reusable-Equipment:before {
  content: "\e98d";
}

.icon-Task-Record-Tag:before {
  content: "\e99b";
}

.icon-Business-Event-logs:before {
  content: "\e99c";
}

.icon-Create-Sample-Test:before {
  content: "\e99d";
}

.icon-Measurement-Session:before {
  content: "\e99e";
}

.icon-Outstanding-Validation:before {
  content: "\e99f";
}

.icon-Published-Configuration-Validation:before {
  content: "\e9a0";
}

.icon-Remaining-Work:before {
  content: "\e9a1";
}

.icon-Status-Overview:before {
  content: "\e9a2";
}

.icon-Lab-Batch-Preparation:before {
  content: "\e9a3";
}

.icon-Result-Entry:before {
  content: "\e9a4";
}

.icon-Baby-Food:before {
  content: "\e9a5";
}

.icon-CT:before {
  content: "\e9a6";
}

.icon-Organic:before {
  content: "\e9a7";
}

.icon-Configuration:before {
  content: "\e9a8";
}

.icon-Approve:before {
  content: "\e9a9";
}

.icon-Translate-Text:before {
  content: "\e9aa";
}

.icon-Generate-Translation-File:before {
  content: "\e9ab";
}

.icon-Translation:before {
  content: "\e9ac";
}

.icon-More-2:before {
  content: "\e9ad";
}

.icon-Drag-Drop:before {
  content: "\e9ae";
}

.icon-Unlink:before {
  content: "\e9af";
}

.icon-Dripper:before {
  content: "\e9b0";
}

.icon-Droplets:before {
  content: "\e9b1";
}

.icon-Lab-Repetition-Failed:before {
  content: "\e9b2";
}

.icon-Operations:before {
  content: "\e9b3";
}

.icon-Lab-Repetition-Success:before {
  content: "\e9b4";
}

.icon-Migrate-Keys:before {
  content: "\e9b5";
}

.icon-dashboard:before {
  content: "\e9b6";
}

.icon-eye:before {
  content: "\e9b7";
}

.icon-play:before {
  content: "\e9b8";
}

.icon-realtime:before {
  content: "\e9b9";
}

.icon-small-mode:before {
  content: "\e9ba";
}

.icon-view_user:before {
  content: "\e9bb";
}

.icon-code_reference:before {
  content: "\e9bc";
}

.icon-colors:before {
  content: "\e9bd";
}

.icon-component:before {
  content: "\e9be";
}

.icon-get-started:before {
  content: "\e9bf";
}

.icon-iconography:before {
  content: "\e9c0";
}

.icon-paint-brush:before {
  content: "\e9c1";
}

.icon-typography:before {
  content: "\e9c2";
}

.icon-power_off:before {
  content: "\e9c3";
}

.icon-whats_new:before {
  content: "\e9c4";
}

.icon-notes:before {
  content: "\e9c5";
}

.icon-istobereported:before {
  content: "\e9c6";
}

.icon-shortmenu:before {
  content: "\e9c7";
}

.icon-labsite:before {
  content: "\e9c8";
}

.icon-menu:before {
  content: "\e9c9";
}

.icon-aetr-mapping:before {
  content: "\e9ca";
}

.icon-log-point-config:before {
  content: "\e9cb";
}

.icon-log-point:before {
  content: "\e9cc";
}

.icon-manage-qc-type:before {
  content: "\e9cd";
}

.icon-method-template:before {
  content: "\e9ce";
}

.icon-plate-creation:before {
  content: "\e9cf";
}

.icon-plate-layout:before {
  content: "\e9d0";
}

.icon-process:before {
  content: "\e9d1";
}

.icon-reagent-type:before {
  content: "\e9d2";
}

.icon-worklist:before {
  content: "\e9d3";
}

.icon-duplicate:before {
  content: "\e9d4";
}

.icon-print-label:before {
  content: "\e9d5";
}

.icon-import-report:before {
  content: "\e9d8";
}

.icon-configure-well-colors:before {
  content: "\e9d6";
}

.icon-free-text-comments:before {
  content: "\e9d7";
}

.icon-manage-user:before {
  content: "\e9d9";
}

.icon-business-role-property:before {
  content: "\e9da";
}

.icon-business-role:before {
  content: "\e9db";
}

.icon-manage-business-role:before {
  content: "\e9dc";
}

.icon-platelayout-hide:before {
  content: "\e9dd";
}

.icon-pipetting-scheme:before {
  content: "\e9de";
}

.icon-pipetting-scheme-hide:before {
  content: "\e9df";
}

.icon-formula-list:before {
  content: "\e9e0";
}

.icon-thumbs-up:before {
  content: "\e9e1";
}

.icon-thumbs-down:before {
  content: "\e9e2";
}

.icon-spiral:before {
  content: "\e9e3";
}

.icon-save-merge:before {
  content: "\e9e4";
}

.icon-filters-column:before {
  content: "\e9e5";
}

.icon-ic-clock:before {
  content: "\e930";
}

.icon-icon-hamburger:before {
  content: "\e92f";
}

.icon-ic_alert:before {
  content: "\e900";
}

.icon-ic_fav-empty:before {
  content: "\e901";
}

.icon-ic_fav-filled:before {
  content: "\e902";
}

.icon-ic_filter:before {
  content: "\e903";
}

.icon-ic_filter-close:before {
  content: "\e904";
}

.icon-ic_help:before {
  content: "\e905";
}

.icon-ic_info_filled:before {
  content: "\e906";
}

.icon-ic_more:before {
  content: "\e907";
}

.icon-ic_navigate:before {
  content: "\e908";
}

.icon-ic_prescription:before {
  content: "\e909";
}

.icon-ic_upload-document:before {
  content: "\e90a";
}

.icon-ic-add:before {
  content: "\e90b";
}

.icon-ic-back:before {
  content: "\e90c";
}

.icon-ic-calender:before {
  content: "\e90d";
}

.icon-ic-click-picture:before {
  content: "\e90e";
}

.icon-ic-close:before {
  content: "\e90f";
}

.icon-ic-date:before {
  content: "\e910";
}

.icon-ic-name:before {
  content: "\e93e";
}

.icon-ic-delete:before {
  content: "\e911";
}

.icon-ic-download:before {
  content: "\e912";
}

.icon-ic-edit:before {
  content: "\e913";
}

.icon-ic-expand-nav:before {
  content: "\e914";
}

.icon-ic-gender:before {
  content: "\e915";
}

.icon-ic-left:before {
  content: "\e916";
}

.icon-ic-location:before {
  content: "\e917";
}

.icon-ic-mail:before {
  content: "\e918";
}

.icon-ic-mail-1:before {
  content: "\e919";
}

.icon-ic-mobile:before {
  content: "\e91a";
}

.icon-icon-booking-calendar:before {
  content: "\e91b";
}

.icon-icon-dashboard:before {
  content: "\e91c";
}

.icon-icon-documents:before {
  content: "\e91d";
}

.icon-icon-fav-labs:before {
  content: "\e91e";
}

.icon-icon-find-patient:before {
  content: "\e91f";
}

.icon-icon-my-appointment:before {
  content: "\e920";
}

.icon-icon-Patient-info:before {
  content: "\e921";
}

.icon-icon-qr-code:before {
  content: "\e922";
}

.icon-icon-results:before {
  content: "\e923";
}

.icon-ic-print:before {
  content: "\e924";
}

.icon-ic-replace-prescription:before {
  content: "\e925";
}

.icon-ic-right:before {
  content: "\e926";
}

.icon-ic-scan-barcode:before {
  content: "\e927";
}

.icon-ic-Search:before {
  content: "\e928";
}

.icon-ic-share:before {
  content: "\e929";
}

.icon-ic-test:before {
  content: "\e92a";
}

.icon-ic-tick_circle_filled:before {
  content: "\e92b";
}

.icon-ic-upload:before {
  content: "\e92c";
}

.icon-ic-view-prescription:before {
  content: "\e92d";
}

.icon-ic-visibility:before {
  content: "\e92e";
}

.icon-ic-test-kit-code:before {
  content: "\e931";
}

.icon-ic-ssn:before {
  content: "\e932";
}

.icon-ic-patient-id:before {
  content: "\e933";
}

.icon-ic-passport:before {
  content: "\e934";
}

.icon-icon-testkits-without-result:before {
  content: "\e935";
}

.icon-icon-register-result:before {
  content: "\e936";
}

.icon-icon-admin-manual:before {
  content: "\e937";
}

.icon-ic-call:before {
  content: "\e938";
}

.icon-ic-cancel-testkits:before {
  content: "\e939";
}

.icon-ic-alert:before {
  content: "\e93a";
}

.icon-ic-globe:before {
  content: "\e93b";
}

.icon-ic-notification:before {
  content: "\e93c";
}

.icon-ic-employee:before {
  content: "\e93d";
}

.icon-ic-near-me:before {
  content: "\e942";
}

.icon-ic-add-prescription:before {
  content: "\e943";
}

.icon-icon-calendar-management:before {
  content: "\e944";
}

.icon-ic-manage:before {
  content: "\e945";
}

.icon-ic-cross_circle_filled:before {
  content: "\e946";
}

.icon-ic-remarks:before {
  content: "\e947";
}

.icon-ic-history:before {
  content: "\e948";
}

.icon-ic-rotate-right:before {
  content: "\e949";
}

.icon-ic-rotate-left:before {
  content: "\e94a";
}

.icon-ic-sconfirm:before {
  content: "\e94b";
}

.icon-ic-scheckin:before {
  content: "\e94c";
}

.icon-ic-scheckin-success:before {
  content: "\e94d";
}

.icon-ic-female:before {
  content: "\e94e";
}

.icon-ic-male:before {
  content: "\e94f";
}

.icon-ic-confirm-arrival:before {
  content: "\e950";
}

.icon-ic-register-testkit:before {
  content: "\e951";
}

.icon-ic-preappoint:before {
  content: "\e952";
}

.icon-ic-birthday:before {
  content: "\e953";
}

.icon-ic-refresh:before {
  content: "\e954";
}

.icon-ic-delete-rec:before {
  content: "\e955";
}

.icon-ic-pdf:before {
  content: "\e956";
}

.icon-ic-prescom:before {
  content: "\e957";
}

.icon-ic-regisgternew:before {
  content: "\e958";
}

.icon-ic-mailcircle:before {
  content: "\e965";
}

.icon-ic-sms:before {
  content: "\e959";
}

.icon-ic-whatsapp:before {
  content: "\e95a";
}

.icon-ic-zoomin:before {
  content: "\e95b";
}

.icon-ic-zoomout:before {
  content: "\e95c";
}

.icon-ic-warning:before {
  content: "\e95d";
}

.icon-ic-graph:before {
  content: "\e95e";
}

.icon-ic-resultready:before {
  content: "\e95f";
}

.icon-ic-partialresult:before {
  content: "\e960";
}

.icon-ic-ongoinganaly:before {
  content: "\e961";
}

.icon-ic-samplerecvd:before {
  content: "\e962";
}

.icon-ic-unread:before {
  content: "\e963";
}

.icon-ic_filteraaplied:before {
  content: "\e964";
}

.icon-ic-clear:before {
  content: "\e966";
}