@import "../../../../src/styles/global/base/variables";

.group-title {
  display: block;
  font-family: $font-medium;
  color: rgb($grey-shade-dark);
  font-size: $font-size-root;
  line-height: 50px;
}

.group-title-res {
  display: block;
  font-family: $font-medium;
  color: rgb($grey-shade-dark);
  font-size: $font-size-root;
  margin-bottom: 10px;
}

.mat-button.ghost {
  color: rgb($secondary-color-regular);
  // border-color: rgb($app-link-color);
  border-style: solid;
  border-width: 1.5px;
}

.icon-search-link:before {
  content: "\e996";
  color: rgb($app-link-color);
}

.overlay-popup {
  background-color: rgba($base-color-white, 1);
  box-shadow: 0 1px 6px 1px rgba($grey-shade-dark, 0.2),
    0 2px 2px 0 rgba($grey-shade-dark, 0.05),
    0 1px 5px 0 rgba($grey-shade-dark, 0.05);
}

.left-column {
  background-color: rgba($primary-color-light, 0.1);
  color: rgb($grey-shade-dark);
  padding: $main-container-padding;
  padding-right: 0;
  box-shadow: 2px 0px 5px 0px rgba($grey-shade-light, 0.4);
  height: 100%;
  width: $left-column-width;
  overflow: auto;
  grid-column: 1;
}

.dark-theme {
  .overlay-popup {
    box-shadow: 0 1px 6px 1px rgba($shadow-color, 0.2),
      0 2px 2px 0 rgba($shadow-color, 0.14),
      0 1px 5px 0 rgba($shadow-color, 0.12);
  }

  .left-column {
    box-shadow: 1px 0px 6px 0px rgba($shadow-color, 0.3);
  }

  .sticky-bottom-onSlider {
    box-shadow: 0 1px 6px 1px rgba($shadow-color, 0.5),
      0 2px 2px 0 rgba($shadow-color, 0.05),
      0 1px 5px 0 rgba($shadow-color, 0.05);
  }
}

.right-column {
  grid-column: 2;
  width: 100%;
  overflow: auto;
}

.two-col-container {
  display: grid;
  grid-template-columns: minmax($left-column-width, 10%) auto;
  height: calc(100vh - #{$toolbar-height});
  width: 100%;
}

.main-container-res {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - (#{$toolbar-height} + 50px));
  columns: 1;
  padding: 30px;
}

.main-container {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - (#{$toolbar-height} + 50px));
  columns: 1;
}

.slider-data-container {
  padding: 36px 32px;

  .header {
    overflow: hidden;
  }
}

button:focus {
  outline: none;
}

.sticky-bottom-onSlider {
  display: flex;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
  height: 60px;
  width: 100%;
  background-color: rgb($base-color-white);
  position: absolute;
  bottom: 0;
  box-shadow: 0 1px 6px 1px rgba($shadow-color, 0.2),
    0 2px 2px 0 rgba($shadow-color, 0.05), 0 1px 5px 0 rgba($shadow-color, 0.05);
  opacity: 0.95;
}

.launcher-tab-margin {
  margin-top: $launcher-tab-margin;
}

.launcher-icon-margin {
  margin-top: $launcher-icon-margin;
  height: 400px;
}

// MARGIN  -Extra Small
.m-xs {
  margin: 5px;
}

.m-l-xs {
  margin-left: 5px;
}

.m-r-xs {
  margin-right: 5px;
}

.m-t-xs {
  margin-top: 5px;
}

.m-b-xs {
  margin-bottom: 5px;
}

// MARGIN  -Small
.m-s {
  margin: 10px;
}

.m-l-s {
  margin-left: 10px;
}

.m-r-s {
  margin-right: 10px;
}

.m-t-s {
  margin-top: 10px;
}

.m-b-s {
  margin-bottom: 10px;
}

// MARGIN  -Medium
.m-m {
  margin: 20px;
}

.m-l-m {
  margin-left: 20px;
}

.m-r-m {
  margin-right: 20px;
}

.m-t-m {
  margin-top: 20px;
}

.m-b-m {
  margin-bottom: 20px;
}

// MARGIN  -Extra Large
.m-l {
  margin: 40px;
}

.m-l-l {
  margin-left: 40px;
}

.m-r-l {
  margin-right: 40px;
}

.m-t-l {
  margin-top: 40px;
}

.m-b-l {
  margin-bottom: 40px;
}

//Margin Values
.mr-12 {
  margin-right: 12px;
}

.mr-16 {
  margin-right: 16px;
}

// PADDING  -Extra Small
.p-xs {
  margin: 5px;
}

.p-l-xs {
  margin-left: 5px;
}

.p-r-xs {
  margin-right: 5px;
}

.p-t-xs {
  margin-top: 5px;
}

.p-b-xs {
  margin-bottom: 5px;
}

// PADDING  -Small
.p-s {
  margin: 10px;
}

.p-l-s {
  margin-left: 10px;
}

.p-r-s {
  margin-right: 10px;
}

.p-t-s {
  margin-top: 10px;
}

.p-b-s {
  margin-bottom: 10px;
}

// PADDING  -Medium
.p-m {
  margin: 20px;
}

.p-l-m {
  margin-left: 20px;
}

.p-r-m {
  margin-right: 20px;
}

.p-t-m {
  margin-top: 20px;
}

.p-b-m {
  margin-bottom: 20px;
}

// PADDING  -Large
.p-l {
  margin: 30px;
}

.p-l-l {
  margin-left: 30px;
}

.p-r-l {
  margin-right: 30px;
}

.p-t-l {
  margin-top: 30px;
}

.p-b-l {
  margin-bottom: 30px;
}

// PADDING  -Extra Large
.p-xl {
  margin: 40px;
}

.p-l-xl {
  margin-left: 40px;
}

.p-r-xl {
  margin-right: 40px;
}

.p-t-xl {
  margin-top: 40px;
}

.p-b-xl {
  margin-bottom: 40px;
}

// FONT  SIZES

.font-xs {
  font-size: 10px;
}

.font-s {
  font-size: 12px;
}

.font-m {
  font-size: 14px;
}

.font-l {
  font-size: 16px;
}

.font-xl {
  font-size: 18px;
}

.form-label {
  font-family: $font-medium;
  color: rgb($grey-shade-dark);
}

.form-value {
  font-family: $font-regular;
  color: rgb($grey-shade-medium);
}

.tooltip-style {
  font-size: 14px;
  font-family: $font-regular;
  word-break: break-all;
}

.tooltip-style-warning {
  font-size: 14px;
  font-family: $font-regular;
  text-align: center;
}

.empty-content-box {
  display: flex;
  text-align: center;
  width: 100%;
  height: 190px;
  justify-content: center;
  align-items: center;
  background-color: rgba($primary-color-light, 0.05);
  color: rgb($grey-shade-dark);
  font-family: $font-regular;
}

/* Pagination overriden styles */
.mat-paginator-container {
  flex-direction: row-reverse;
  justify-content: end !important;

  .mat-paginator-range-actions {
    display: inline !important;
  }

  .mat-paginator-range-label {
    display: inline;
    margin: 0 4px !important;
  }

  .mat-paginator-navigation-previous,
  .mat-paginator-navigation-first {
    float: left;
  }

  .mat-paginator-page-size {
    flex-direction: row-reverse;

    .mat-paginator-page-size-label {
      display: none;
    }
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    display: none;
  }

  .mat-select-value {
    text-overflow: clip;
    display: inline;
  }

  .mat-select-arrow,
  button {
    color: rgb($app-link-color);
  }

  .mat-form-field-wrapper {
    padding: 0;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline {
    display: none;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline-thick {
    display: none;
  }

  .mat-form-field-appearance-outline .mat-select-arrow-wrapper {
    transform: none;
  }

  .mat-paginator-page-size-select {
    margin: 0;
  }
}

/* End -- Pagination Styles */

/* Custom dropdown styles to display it as filter */
.dropdown-filter {
  .mat-form-field-infix {
    width: 100%;
    padding: 2px 0;
  }

  .mat-select-value {
    display: inline-block;
    max-width: 100%;
    width: fit-content;
    margin-right: 2px;
  }

  .mat-select-arrow,
  .mat-form-field-label {
    color: rgb($app-link-color);
  }

  .mat-select-arrow {
    transform: translateY(75%) !important;
  }

  .mat-form-field-underline {
    display: none;
  }
}

/* End -- Custom Dropdown */

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

//Overridden Form Styles//
.mat-form-field-wrapper {
  padding: 0;
}

.mat-form-field-appearance-outline .mat-form-field-wrapper {
  margin: 0 !important;
}

ngx-mat-intl-tel-input {
  .country-selector {
    padding: 1px 10px 1px 1px !important;
    border-right: 1px solid rgba($grey-shade-light, 0.3);
  }

  input:not(.country-search) {
    padding: 0 6px 0 100px !important;
  }

  span.country-selector-code:before {
    content: '(';
  }

  span.country-selector-code::after {
    content: ')';
  }
}

.mat-raised-button:not(.mat-button-disabled):active:not([class*="mat-elevation-z"]),
.mat-raised-button:not([class*="mat-elevation-z"]) {
  box-shadow: none;
}

.mat-raised-button:not(.isAdmin .mat-raised-button.mat-primary.mat-button-disabled) {
  color: rgb($base-color-white) !important;
  // background: rgb($app-link-color) !important;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 11px 0;
}

.mat-form-field-infix {
  border-top: 5px solid transparent;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba($grey-shade-light, 0.8)
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  transform: translateY(-16px) scale(0.8);
}

.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
  // background: rgb($app-link-color);
}

.mat-button.mat-primary {
  border: 1px solid rgb($secondary-color-regular);
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgb($app-link-color);
}

.mat-form-field-appearance-outline {
  &.mat-focused .mat-form-field-outline-thick {
    color: rgb($app-link-color);
  }
}

.mat-form-field-appearance-outline:not(.mat-form-field-disabled) .mat-form-field-flex:hover .mat-form-field-outline {
  opacity: 0;
  transition: opacity 600ms cubic-bezier(0.25, 0.8, 0.25, 1)
}

.count-icon {
  background: rgba($primary-color-regular, 0.06) 0% 0% no-repeat padding-box;
  border-radius: 14px;
  font-size: 12px;
  line-height: 24px;
  padding: 5px 12px;
  color: rgb($primary-color-regular);
  font-family: $font-medium;
}

.button_width {
  line-height: 18px;
  font-family: $font-medium;
  min-width: 152px !important;
}

.primary-hover:hover {
  background-color: rgb($secondary-color-dark);
}

.secondary-hover:hover {
  background-color: rgba($secondary-color-regular, 0.16);
}

.horizontal-context {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mat-checkbox-frame {
  border-color: rgb($app-link-color);
  border-radius: 3px;
}

.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick,
.mat-form-field.mat-warn .mat-input-element {
  color: rgb($error-color);
  background: rgba($error-color, 0.05);
}

.mat-form-field-invalid .mat-input-element {
  color: rgb($error-color);
}

.fontclass-medium {
  font-family: $font-medium;
}

.color-error {
  color: rgb($error-color);
}

.color-link,
.icon-color {
  color: rgb($app-link-color);
}

.pointCursor {
  cursor: pointer;
}

//Message displayed for empty grid
.empty_grid_container {
  padding: 50px 0;
  display: grid;
  justify-content: center;

  & p {
    font-size: 18px;
    line-height: 24px;
    color: rgb($grey-shade-medium);
    text-align: center;
  }
}

.virtual-view-port {
  height: calc(100vh - 192px);
}

//Sliders Common Classes
.slider_closebtn {
  line-height: 24px !important;
  min-width: auto !important;
  padding: 0 !important;
}

.dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 2px dashed rgba($grey-shade-dark, 0.3);
  border-radius: 5px;
  padding: 20px;
  width: inherit;
  height: 270px;
  margin-bottom: 20px;
  cursor: pointer;

  &.hovering {
    border: 2px dashed rgb($app-link-color);
  }

  &.invalid {
    border: 2px dashed rgb($error-color);
  }
}

.dropzone-text {
  display: grid;
  text-align: center;
  word-wrap: normal;
}

.dropzone-main-text {
  font-size: 16px;
  line-height: 20px;
  font-family: $font-medium;
  margin-bottom: 4px;
  color: rgb($grey-shade-dark);
  margin-top: 20px;
}

.dropzone-sub-text {
  font-size: 14px;
  line-height: 20px;
  font-family: $font-regular;
  color: rgb($grey-shade-medium);
}

.upload-header {
  color: rgb($grey-shade-dark);
  letter-spacing: 1.2px;
  font-size: 12px;
  line-height: 24px;
  text-transform: uppercase;
  margin: 24px 0 16px;
  font-family: $font-medium;
}

.doc_uploaded_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  background: rgb($base-color-white) 0% 0% no-repeat padding-box;
  border: 1px solid rgba($primary-color-regular, 0.08);
  border-radius: 4px;
  padding: 8px 10px 8px 20px;
  margin-top: 24px;

  .doc-details {
    margin: 0 16px;
    width: 265px;
  }

  .docfileName {
    line-height: 20px;
    font-family: $font-medium;
    letter-spacing: 0px;
    color: rgb($grey-shade-dark);
    margin: 0;
  }

  .docSize {
    font-size: 12px;
    line-height: 16px;
    font-family: $font-regular;
    letter-spacing: 0px;
    color: rgb($grey-shade-light);
    margin: 0;
  }

  .icons-container {
    display: none;
  }
}

.doc_uploaded_container:hover {
  background: rgb($table-row-hover-color);

  .icons-container {
    display: flex;
    justify-content: center;
  }
}

.actions {
  text-align: center;
  margin-top: 44px;
}

.form-layout {
  display: flex;
  flex-direction: column;
}

.browseFile_label {
  margin-top: 16px;
  color: rgb($grey-shade-dark);
}

.commonBrowsebtn {
  background-color: inherit;
  border: none;
  top: 9px;
  right: 8px;
  position: absolute;
  color: rgb($app-link-color);
  cursor: pointer;
  font-family: $font-medium;
}

.pos_relative {
  position: relative;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.loader {
  font-size: 10px;
  position: relative;
  border: 3px solid;
  border-color: rgb($primary-color-regular);
  border-left-color: rgba($primary-color-regular, 0.1);
  animation: load8 1.5s infinite linear;
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.grid-detail-row {
  min-height: 0 !important;
  padding: 0 !important;
  border-bottom: none;
  background-color: rgb($table-row-hover-color);

  .expanded-cell {
    padding: 0 !important;
  }
}

.grid-element-detail {
  width: 100% !important;
}

.grid-expanded-row {
  border-bottom: none;
}

.expanded-cell .grid-element-detail .mat-row:not(:last-child) {
  border-bottom: none;
}

.expanded-cell .grid-element-detail .mat-row:hover {
  background-color: rgb($table-row-hover-color);
}

.mat-form-field {
  width: 100%;
}

@media screen and (max-width: 1440px) {
  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 11px 0;
  }

  .tooltip-style {
    font-size: 12px;
  }

  .empty_grid_container {
    & p {
      font-size: 16px;
      line-height: 22px;
    }
  }
}

.mat-menu-panel {
  max-height: 350px !important;
}

.mat-select-arrow {
  color: rgb($app-link-color);
}

.popup-heading {
  text-align: center;
  font: normal normal bold 18px / 24px Arial;
  letter-spacing: 0px;
  color: rgb($primary-color-regular);
}

.subtext-hint {
  text-align: center;
  font: normal normal normal 14px/20px Arial;
  letter-spacing: 0px;
  color: #666666;
}

.important-msg {
  text-align: center;
  font: italic normal normal 12px / 20px Arial;
  letter-spacing: 0px;
  color: #666666;
  opacity: 1;
}

.email-phone-bold {
  text-align: center;
  font: normal normal bold 14px/20px Arial;
  letter-spacing: 0px;
  color: #333333;
}

