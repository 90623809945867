@import "styles/global/main";
@import "styles/app";
@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import url('https://fonts.googleapis.com/css2?family=Noto+Emoji&display=swap');

.calendar-blocked-event {
    //background-image: linear-gradient(29deg, #f4f4f4 20%, #ffffff 25%, #ffffff 50%, #f4f4f4 50%, #f4f4f4 75%, #ffffff 75%, #ffffff 100%);
    background-size: 20px 10px;
    border-left: 3px solid #757575;
    pointer-events: none !important;
    border-bottom: 3px solid #fff !important;
}

.calendar-general-event {
    border-left: 3px solid #0197f4 !important;
    //background-image: linear-gradient(29deg, #E9FDEA 25%, #ffffff 25%, #ffffff 50%, #E9FDEA 50%, #E9FDEA 75%, #ffffff 75%, #ffffff 100%);
    background-size: 20px 10px;
    cursor: pointer;
    border-bottom: 3px solid #fff !important;
}

.calendar-booked-event {
    border-left: 3px solid #c33149 !important;
    //background-image: linear-gradient(29deg, #ffe4e9 25%, rgb($base-color-white) 25%, rgb($base-color-white) 50%, #ffe4e9 50%, #ffe4e9 75%, rgb($base-color-white) 75%, #ffffff 100%);
    background-size: 20px 10px;
    cursor: pointer;
    border-bottom: 3px solid #fff !important;
    pointer-events: none !important;
    background-color: #b3afaf !important;
}

.calendar-booked-event-admin {
    border-left: 3px solid #c33149 !important;
    //background-image: linear-gradient(29deg, #ffe4e9 25%, rgb($base-color-white) 25%, rgb($base-color-white) 50%, #ffe4e9 50%, #ffe4e9 75%, rgb($base-color-white) 75%, #ffffff 100%);
    background-size: 20px 10px;
    cursor: pointer;
    border-bottom: 3px solid #fff !important;
}

.bluebold {
    color: rgb(var(--link-color));
    font-weight: bolder;
    font-size: 12px;
    font-family: $font-medium;
}

.boldText {
    font-weight: bolder;
    font-size: 12px;
}

// .mat-input-element[type=time] {
//     height: 6px;
// }
.verify-btn {

    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (min-width:962px) {
    //   height: 32px;
    }
    
    ::ng-deep .mat-button-wrapper {
        font-family: 'Arial-Medium';
        font-size: 14px;
        line-height: 18px;
    }
  }
  .otp-container-text {
    text-align: center;
      font: normal normal normal 14px/20px Arial;
      letter-spacing: 0px;
      color: #666666;
      margin: 0;
      // width: 340px;
  
  }
  .otp-heading {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
    color: #003883;
    font: normal normal bold 18px/24px Arial;
  }