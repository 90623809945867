@import "../../../../src/styles/global/base/color";

.overlay-container .toast-container .ng-toast {
  background-color: #24A14833;
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 1px 15px 1px;
  width: 450px;
  border-radius: 3px 3px 3px 3px;
  color: rgb($grey-shade-dark);
  pointer-events: all;
  cursor: pointer;
  top: 60px;

  @media (max-width: 768px) {
    width: 250px !important; //  Specify toastr-dialog width only on small devices 
  }
}

.border-p {
  border: 1px solid rgb($primary-color-dark);
  border-left: 10px solid rgb($primary-color-dark);
}

.border-w {
  border: 1px solid rgb($warning-color);
  border-left: 10px solid rgb($warning-color);
}

.border-s {
  border: 1px solid rgb($success-color);
  border-left: 10px solid rgb($success-color);
}

.border-a {
  border: 1px solid rgb($error-color);
  border-left: 10px solid rgb($error-color);
}

.custom-title {
  color: rgb($grey-shade-dark);
}

.custom-message {
  padding-left: 10px;
}