.circle {
    display: inline-block;
    text-align: center;
    position: relative;
    border-radius: 50%;
    height: $css-icon-size;
    width: $css-icon-size;
    min-height: $css-icon-size;
    min-width: $css-icon-size;
    font-size: $font-size-root;
    line-height: $css-icon-size;
    color: rgb($base-color-white);
    border: 1.5px solid rgb($app-link-color);
  }

  .circle-checked {
    background-color: rgb($app-link-color);
  }

  .circle-unchecked {
    background-color: rgba($primary-color-light, 0.5);
  }
  .point-blip {
    display: block;
    padding: 0;
    background: rgb($app-link-color);
    -webkit-box-shadow: 0 0 9.5px 0.5px rgba($grey-shade-dark, 0.3);
    box-shadow: 0 0 9.5px 0.5px rgba($grey-shade-dark, 0.3);
    width: 8px;
    height: 8px;
    text-indent: -9999px;
    font-size: 0;
    line-height: 0;
    -webkit-transition: opacity .5s ease-out,-webkit-transform .25s;
    -o-transition: opacity .5s ease-out,transform .25s;
    transition: opacity .5s ease-out,transform .25s;
    transition: opacity .5s ease-out,transform .25s,-webkit-transform .25s;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: 100%;
    pointer-events: none;
    margin: 7px 0 0 20px;
    position: absolute;
  }

  .point-blip:before, .point-blip:after{
    content: "";
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      position: absolute;
      background: rgba($app-link-color, 0.8);      
      border-radius: 50%;
      border: 1px solid rgb($app-link-color);
      -webkit-animation: ripple-circle 1.7s infinite ease-out;
      animation: ripple-circle 1.7s infinite ease-out;
      width: 30px;
      height: 30px;
      top: -11px;
      left: -11px;
      z-index: -1;
  }

  .point-blip:after{
    -webkit-animation: ripple-circle-inner 1.7s infinite ease-out;
    animation: ripple-circle-inner 1.7s infinite ease-out;
  }

  @-webkit-keyframes ripple-circle-inner{
    0%{opacity:0;-webkit-transform:scale(.1);transform:scale(.1)}
    24%,90%{opacity:0}
    25%{opacity:.65;-webkit-transform:scale(.1);transform:scale(.1)}
    100%{opacity:0;-webkit-transform:scale(1);transform:scale(1)}
  }

  @keyframes ripple-circle-inner{
    0%{opacity:0;-webkit-transform:scale(.1);transform:scale(.1)}
    24%,90%{opacity:0}
    25%{opacity:.65;-webkit-transform:scale(.1);transform:scale(.1)}
    100%{opacity:0;-webkit-transform:scale(1);transform:scale(1)}
  }

  @-webkit-keyframes ripple-circle{
    0%{opacity:.65;-webkit-transform:scale(.1);transform:scale(.1)}
    100%{opacity:0;-webkit-transform:scale(1);transform:scale(1)}}

  @keyframes ripple-circle{
    0%{opacity:.65;-webkit-transform:scale(.1);transform:scale(.1)}
    100%{opacity:0;-webkit-transform:scale(1);transform:scale(1)}
  }
