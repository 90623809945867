// mat checkboxes inherit themes from parent, by default the checkbox theme uses accent (orange) color
// so we have to explicitly set theming for checkboxes
// As for dark theme we have to re-write all the below classes again which ends up increasing LOC
// !important tag required here because of above reasons
.mat-checkbox-frame {
  border-radius: 3px;
  border: 1.5px solid rgb($app-link-color) !important;
  height: 16px;
  width: 16px;
  border-width: 1.5px;
}

.mat-checkbox-layout {
  margin-top: 5px;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: rgb($app-link-color) !important;
  height: 16px;
  width: 16px;
}

mat-checkbox.label {
  margin-bottom: 0px;
}

mat-option.multi-select mat-pseudo-checkbox.mat-option-pseudo-checkbox.mat-pseudo-checkbox {
  color: rgb($app-link-color);
}

mat-option.multi-select mat-pseudo-checkbox.mat-option-pseudo-checkbox.mat-pseudo-checkbox-checked {
  background-color: rgb($app-link-color) !important;
}

mat-pseudo-checkbox.mat-pseudo-checkbox.mat-pseudo-checkbox-checked {
  background-color: rgb($app-link-color) !important;
}

mat-pseudo-checkbox.mat-pseudo-checkbox {
  color: rgb($app-link-color) !important;
  border: 1.5px solid;
}

mat-pseudo-checkbox.mat-pseudo-checkbox-checked::after {
  top: 3.5px;
  left: 0.5px;
  width: 10px;
  height: 3px;
  border-left: 1.5px solid currentColor;
  border-bottom: 1.5px solid currentColor;
}

.mat-radio-outer-circle {
  border-color: rgb($app-link-color);
}


.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: rgb($app-link-color);
}