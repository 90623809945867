@import "../../../../src/styles/global/base/color";

@mixin styleEditedStepIcon($index) {
  .mat-horizontal-stepper-header:nth-child(#{$index}) {
    .mat-step-icon:not(.mat-step-icon-selected) {
      background-color: rgb($stepper-green);
    }
  }
}

@mixin styleUnEditedStepIcon($index) {
  .mat-horizontal-stepper-header:nth-child(#{$index}) {
    .mat-step-icon:not(.mat-step-icon-selected) {
      background-color: transparent;
      border: solid 1px rgb($primary-color-regular);
      color: rgb($primary-color-regular);
    }
  }
}

@mixin styleEditedStepLabelColor($index) {
  .mat-horizontal-stepper-header:nth-child(#{$index}) {
    .mat-step-label {
      color: #0197f4;

    }
  }
}

@mixin styleUnEditedStepLabelColor($index) {
  .mat-horizontal-stepper-header:nth-child(#{$index}) {
    .mat-step-label {
      color: #666666;

    }
  }
}

// This method is not used yet, if you want to apply font-family
// Please change the font family and call the method.
@mixin styleSelectedStepLabelColor($index) {
  .mat-horizontal-stepper-header:nth-child(#{$index}) {
    .mat-step-label {
      color: #666666;
      font-family: 'Courier New', Courier, monospace;
    }
  }
}

.last-edited-step-0 {
  @include styleUnEditedStepIcon(3);
  @include styleUnEditedStepIcon(5);
  @include styleUnEditedStepIcon(7);
  @include styleUnEditedStepIcon(9);

  @include styleUnEditedStepLabelColor(3);
  @include styleUnEditedStepLabelColor(5);
  @include styleUnEditedStepLabelColor(7);
  @include styleUnEditedStepLabelColor(9);
}

.last-edited-step-1 {
  @include styleEditedStepIcon(1);

  @include styleEditedStepLabelColor(1);

  @include styleUnEditedStepIcon(5);
  @include styleUnEditedStepIcon(7);
  @include styleUnEditedStepIcon(9);

  @include styleUnEditedStepLabelColor(5);
  @include styleUnEditedStepLabelColor(7);
  @include styleUnEditedStepLabelColor(9);
}

.last-edited-step-2 {
  @include styleEditedStepIcon(1);
  @include styleEditedStepIcon(3);

  @include styleUnEditedStepIcon(7);
  @include styleUnEditedStepIcon(9);

  @include styleEditedStepLabelColor(1);
  @include styleEditedStepLabelColor(3);

  @include styleUnEditedStepLabelColor(7);
  @include styleUnEditedStepLabelColor(9);
}

.last-edited-step-3 {
  @include styleEditedStepIcon(1);
  @include styleEditedStepIcon(3);
  @include styleEditedStepIcon(5);

  @include styleUnEditedStepIcon(9);

  @include styleEditedStepLabelColor(1);
  @include styleEditedStepLabelColor(3);
  @include styleEditedStepLabelColor(5);

  @include styleUnEditedStepLabelColor(9);
}

.last-edited-step-4 {
  @include styleEditedStepIcon(1);
  @include styleEditedStepIcon(3);
  @include styleEditedStepIcon(5);
  @include styleEditedStepIcon(7);

  @include styleEditedStepLabelColor(1);
  @include styleEditedStepLabelColor(3);
  @include styleEditedStepLabelColor(5);
  @include styleEditedStepLabelColor(7);
}

.last-edited-step-5 {
  @include styleEditedStepIcon(1);
  @include styleEditedStepIcon(3);
  @include styleEditedStepIcon(5);
  @include styleEditedStepIcon(7);
  @include styleEditedStepIcon(9);

  @include styleEditedStepLabelColor(1);
  @include styleEditedStepLabelColor(3);
  @include styleEditedStepLabelColor(5);
  @include styleEditedStepLabelColor(7);
  @include styleEditedStepLabelColor(9);
}

.mat-horizontal-stepper-header-container {
  width: 59vw;
  margin-left: auto;

  @media screen and (max-width: 1024px) {
    width: 65vw;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  .mat-horizontal-stepper-header {
    background-color: transparent !important;

    .mat-step-header-ripple {
      display: none;
    }

    .mat-step-label {
      padding-top: 4px !important;
    }

    .mat-step-icon-selected {
      background-color: rgb($stepper-blue);
    }
  }
}