/**********************************************************/
/* Loader animation appears on app start
/*****************************************************************/
@import "../../../../src/styles/global/base/color";

.overlay-spin-window {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1010;
  background: rgba(var(--grey-shade-light), 0.3);
}

.overlay-spin-window-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.lds-spinner {
  color: rgb($app-link-color);
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.lds-spinner div {
  transform-origin: 32px 32px;
  animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 29px;
  width: 2px;
  height: 13px;
  border-radius: 50%;
  background: rgb($app-link-color);
}

.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.placeholder-loader {
  height: 16px;
  background: linear-gradient(90deg,
      rgb($primary-color-light, 0.2),
      rgb($primary-color-light, 0.3),
      rgb($primary-color-light, 0.4),
      rgb($primary-color-light, 0.4));
  background-size: 400% 100%;
  border-radius: 2px;
  animation: css-loader 1s ease-in-out infinite;
}

@keyframes css-loader {
  0% {
    background-position: 0 100%;
  }

  50% {
    background-position: 100% 100%;
  }

  100% {
    background-position: 0 100%;
  }
}