body,
html {
  display: flex;
  flex-direction: column;
  height: 100vh;

  &.hideScroll {
    overflow: hidden;
  }

  app-root {
    flex-grow: 1;
  }
}

.radius-4 {
  border-radius: 4px;
}

.radius-8 {
  border-radius: 8px;
}

.border-box {
  border: 1px solid rgba(var(--primary-color-light), 0.4);
  padding: 20px;
}

.ecl-container {
  width: 1400px;
  margin: 0 auto;
  display: flex;

  &.isAdmin {
    width: 100%;
  }

  @media screen and (max-width: 1399px) {
    width: 100%;
  }
}

.tab-app {
  width: 100%;

  .mat-tab-header {
    margin-bottom: 20px;
  }

  .mat-tab-labels {
    justify-content: flex-start;

    .mat-tab-label {
      margin: 0;

      @media screen and (max-width: 768px) {
        padding: 5px 10px;
      }
    }

    .mat-tab-label-content {
      font-size: $font-size-root;

      @media screen and (max-width: 768px) {
        font-size: $font-size-root - 2;
      }
    }
  }

  .mat-tab-list {
    border-bottom: 2px solid rgba($primary-color-regular, 0.15);
  }

  .mat-ink-bar {
    bottom: -3px;
  }

  .mat-tab-body {
    animation: fade-out 0.8s;
    opacity: 0;
  }

  .mat-tab-body.mat-tab-body-active {
    animation: fade-in 0.8s;
    opacity: 1;
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes fade-out {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }
}

.ecl-table {
  position: relative;

  .top-action {
    display: none;
  }

  thead {
    th.mat-header-cell {
      border: none;
      padding: 15px;
    }
  }

  tbody,
  tfoot {
    tr {
      .action-btn {
        display: flex;
        align-items: center;
        flex-direction: row;
        // visibility: hidden;
        // transform: translateX(-10%);
        // opacity: 0;
        transition: all 0.2s ease-in-out;

        &.stay {
          transform: translateX(0);
          opacity: 1;
          visibility: visible;
        }
      }

      .mobile-action {
        display: none;
      }

      &:hover {
        background-color: rgb($table-row-hover-color);
        cursor: pointer;

        .action-btn {
          visibility: visible;
          opacity: 1;
          transform: translateX(0);
        }
      }

      td.mat-cell {
        border-bottom: 2px solid rgba($primary-color-regular, 0.15);
        color: rgb($grey-shade-dark);
        padding: 15px;
        vertical-align: top;

        span {
          display: block;
          width: 100%;
          line-height: 18px;
          word-break: break-word;

          &.subText {
            color: rgb($grey-shade-light);
            font-size: $font-size-root - 2;
          }
        }
      }

      &:first-child {
        td {
          border-top: 2px solid rgba($primary-color-regular, 0.15);

          &:first-child {
            border-top-left-radius: 10px;
            border-left: 2px solid rgba($primary-color-regular, 0.15);
          }

          &:last-child {
            border-top-right-radius: 10px;
            border-right: 2px solid rgba($primary-color-regular, 0.15);
          }
        }
      }

      td {
        &:first-child {
          border-left: 2px solid rgba($primary-color-regular, 0.15);
        }

        &:last-child {
          border-right: 2px solid rgba($primary-color-regular, 0.15);
        }
      }

      &:last-child {
        td {
          border-bottom: 2px solid rgba($primary-color-regular, 0.15);

          &:first-child {
            border-bottom-left-radius: 10px;
            border-left: 2px solid rgba($primary-color-regular, 0.15);
          }

          &:last-child {
            border-bottom-right-radius: 10px;
            border-right: 2px solid rgba($primary-color-regular, 0.15);
          }
        }
      }
    }
  }

  tfoot {
    tr {
      &:hover {
        background-color: transparent !important;
      }
    }

    .mat-column-noRecord {
      text-align: center;
      height: 300px;

      .desc {
        padding-top: 30px;

        p {
          margin: 0;
          color: rgba(102, 102, 102, 0.6);
        }
      }
    }
  }

  @media (max-width: 962px) {
    &:not(&.no-responsive) {
      .top-action {
        display: block;
        position: absolute;
      }

      tbody {
        display: flex;
        flex-direction: column;
      }

      .mat-header-row {
        display: none;
      }

      .mat-row {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: auto;
        border: 2px solid rgba($primary-color-regular, 0.15);

        &:not(:first-child) {
          margin-top: 20px;
        }

        padding: 10px;
        border-radius: 8px;

        td {
          width: 100%;
          border: none !important;
          padding: 5px !important;
        }

        .mat-column-actions {
          display: none;
        }

        .force-display {
          display: block;

          .action-btn {
            display: none;
          }

          .mobile-action {
            display: flex;
            flex-direction: column;

            button {
              padding-top: 5px;
              padding-bottom: 5px;
            }
          }
        }
      }
    }
  }
}

.table-popover {
  position: relative;
  overflow: visible !important;

  .arrow-top {
    position: absolute;
    top: -5px;
    right: 9px;
    z-index: 1;

    &:after {
      content: "";
      position: absolute;
      width: 15px;
      height: 15px;
      background: #fff;
      transform: rotate(45deg);
      top: 0;
      right: 0;
      box-shadow: -1px -1px 10px -2px rgb(0 0 0 / 50%);
    }
  }

  .mat-menu-content {
    padding: 0 !important;
  }

  .main {
    background: #fff;
    position: relative;
    z-index: 2;
  }
}

.rm-popover {
  position: relative;
  overflow: visible !important;
  margin-top: 11px;

  .arrow-top {
    position: absolute;
    top: -4px;
    right: 3px;
    z-index: -1;

    &:after {
      content: "";
      position: absolute;
      width: 15px;
      height: 15px;
      background: #fff;
      transform: rotate(45deg);
      top: 0;
      right: 0;
      box-shadow: -1px -1px 10px -2px rgb(0 0 0 / 50%);
    }
  }

  .mat-menu-content {
    padding: 0 !important;
  }

  .mat-menu-item {
    padding: 0px;
  }
}

.establish-popover {
  position: relative;
  overflow: visible !important;
  margin-top: 11px;

  .arrow-top {
    position: absolute;
    top: -4px;
    left:20px;
    z-index: -1;

    &:after {
      content: "";
      position: absolute;
      width: 15px;
      height: 15px;
      background: #fff;
      transform: rotate(45deg);
      top: 0;
      right: 0;
      box-shadow: -1px -1px 10px -2px rgb(0 0 0 / 50%);
    }
  }

}

.comment-popover {
  position: relative;
  overflow: visible !important;
  // margin-bottom:5px;
  padding: 5px;
  min-height: 0px !important;
  min-width: 0px !important;

  .arrow-top {
    position: absolute;
    top: 75%;
    left:19px;
    z-index: -1;

    &:after {
      content: "";
      position: absolute;
      width: 15px;
      height: 15px;
      background: #fff;
      transform: rotate(45deg);
      top: 0;
      right: 0;
      box-shadow: -1px -1px 10px -2px rgb(0 0 0 / 50%);
    }
  }
  span{
    font-size: 14px;
  }
  .mat-menu-content {
    padding: 0 !important;
  }
}

.user-menu-popover {
  width: 310px !important;
  margin-top: 13px;
  position: relative;
  overflow: visible !important;

  .mat-menu-content {
    padding: 0 !important;
  }

  .arrow-top {
    position: absolute;
    top: -5px;
    right: 5px;
    z-index: 1;

    &:after {
      content: "";
      position: absolute;
      width: 15px;
      height: 15px;
      background: #fff;
      transform: rotate(45deg);
      top: 0;
      right: 0;
      box-shadow: -1px -1px 10px -2px rgb(0 0 0 / 50%);
    }
  }

  .user-main {
    position: relative;
    z-index: 2;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(136, 151, 190, 0.4);

    .user-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgb($primary-color-regular);
      background: rgba($primary-color-regular, 0.2);
      width: 64px;
      height: 64px;
      border-radius: 50%;

      .icon-user {
        color: #576eaf8b;
      }
    }

    .user-infor {
      width: calc(100% - 77px);

      p {
        margin: 0;
        text-overflow: ellipsis;
        overflow: hidden;

        &.name {
          font-size: $font-size-root;
          color: rgb($grey-shade-dark);
          font-weight: bold;
        }

        &.email {
          font-size: $font-size-root - 2;
          color: #333333;
        }
      }
    }
  }

  .user-nav {
    border-bottom: 1px solid rgba(136, 151, 190, 0.4);

    .mat-menu-item {
      padding: 0 !important;
      margin-top: 0 !important;
      height: auto;
      line-height: inherit;

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  .sign-out {
    .mat-menu-item {
      padding: 0 !important;
      margin: 0 !important;
    }
  }
}

.slot-date-menu{
  max-width:336px !important;
  width: 336px !important;
}

.toolbar-lang-menu {
  min-width: 200px !important;

  .mat-menu-item {
    display: flex;
    gap: 20px;

    span {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 26px;
      height: 19px;

      img {
        width: 100%;
      }
    }
  }
}

.phone-ctrl {
  .country-selector {
    opacity: 1 !important;
  }

  .mat-form-field-subscript-wrapper {
    position: relative;
  }
}

mat-select-trigger {
  &.country {
    img {
      width: 40px;
      height: 20px;
    }

    .hyphen {
      display: inline-block;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.country {
  .mat-option-text {
    display: flex;
    gap: 10px;
    align-items: center;

    img {
      width: 40px;
      height: 20px;
      object-fit: cover;
    }
  }
}

.ecl-table-result {
  position: relative;

  .top-action {
    display: none;
  }

  thead {
    th.mat-header-cell {
      border: none;
      padding: 15px;
    }
  }

  th.mat-header-cell,
  td.mat-cell,
  td.mat-footer-cell {
    padding: 10px;
  }

  tbody,
  tfoot {
    tr {
      .action-btn {
        display: flex;
        align-items: center;
        flex-direction: column;
        // visibility: hidden;
        // transform: translateX(-10%);
        // opacity: 0;
        transition: all 0.2s ease-in-out;

        &.stay {
          transform: translateX(0);
          opacity: 1;
          visibility: visible;
        }
      }

      .mobile-action {
        display: none;
      }

      &:hover {
        background-color: rgb($table-row-hover-color);
        cursor: pointer;

        .action-btn {
          visibility: visible;
          opacity: 1;
          transform: translateX(0);
        }
      }

      td.mat-cell {
        border-bottom: 2px solid rgba($primary-color-regular, 0.15);
        color: rgb($grey-shade-dark);
        vertical-align: top;
        width: 13%;

        @media screen and (max-width: 962px) {
          width: 100%;
        }

        span {
          display: block;
          width: 100%;
          line-height: 18px;

          &.title {
            display: flex;

            .onlyMobile {
              display: none;
            }

            @media screen and (max-width: 962px) {
              .onlyMobile {
                display: block !important;
                padding-right: 20px;
                width: auto;
              }
            }
          }

          &.subText {
            color: rgb($grey-shade-light);
            font-size: $font-size-root - 2;

            @media screen and (max-width: 962px) {
              padding-left: 42px;
            }
          }
        }
      }

      &:first-child {
        td {
          border-top: 2px solid rgba($primary-color-regular, 0.15);

          &:first-child {
            border-top-left-radius: 10px;
            border-left: 2px solid rgba($primary-color-regular, 0.15);
          }

          &:last-child {
            border-top-right-radius: 10px;
            border-right: 2px solid rgba($primary-color-regular, 0.15);
          }
        }
      }

      td {
        &:first-child {
          border-left: 2px solid rgba($primary-color-regular, 0.15);
        }

        &:last-child {
          border-right: 2px solid rgba($primary-color-regular, 0.15);
        }
      }

      &:last-child {
        td {
          border-bottom: 2px solid rgba($primary-color-regular, 0.15);

          &:first-child {
            border-bottom-left-radius: 10px;
            border-left: 2px solid rgba($primary-color-regular, 0.15);
          }

          &:last-child {
            border-bottom-right-radius: 10px;
            border-right: 2px solid rgba($primary-color-regular, 0.15);
          }
        }
      }
    }
  }

  tfoot {
    tr {
      &:hover {
        background-color: transparent !important;
      }
    }

    .mat-column-noRecord {
      text-align: center;
      height: 300px;

      .desc {
        padding-top: 30px;

        p {
          margin: 0;
          color: rgba(102, 102, 102, 0.6);
        }
      }
    }
  }

  @media (max-width: 962px) {
    .top-action {
      display: block;
      position: absolute;
    }

    tbody {
      display: flex;
      flex-direction: column;
    }

    .mat-header-row {
      display: none;
    }

    .mat-row {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: auto;
      width: 95%;
      margin-left: auto;
      margin-right: auto;
      border: 2px solid rgba($primary-color-regular, 0.15);
      margin-top: 20px;
      padding: 10px;
      border-radius: 8px;

      td {
        width: 100%;
        border: none !important;
        padding: 5px !important;
      }

      .mat-column-actions {
        display: none;
      }

      .force-display {
        display: block;

        .action-btn {
          display: none;
        }

        .mobile-action {
          display: flex;
          flex-direction: column;

          button {
            padding-top: 5px;
            padding-bottom: 5px;
          }
        }
      }
    }
  }
}

/***************** Receptionist booking Calendar CSS START *****************/
.bookmain-calendar {
  .cal-current-time-marker {
    display: none;
  }

  .cal-day-headers {
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    box-shadow: -7px 6px 38px -27px rgb(0 0 0);
    z-index: 1;
    position: relative;

    @media screen and (max-width: 962px) {
      padding-left: 0;
    }

    .cal-header {
      //display: flex;
      gap: 5px;
      justify-content: center;
      align-items: center;
      height: 50px;
      color: #215293;
      padding: 0px;

      &:hover {
        background: none;
      }

      /*span,
      br {
        //display: none;
      }*/
      &:not(.cal-today) {
        border: none !important;
      }

      &.cal-today {
        background-color: #d9e1ed;
        border-radius: 25px;
        border: 2px solid #829dc2;
      }
    }
  }

  .cal-day-columns {
    .cell-highlight:hover {
      &::before {
        content: "+";
        position: absolute;
        font-size: $font-size-root + 16;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin-top: auto;
        margin-bottom: auto;
        width: 20px;
        height: 20px;
        color: rgb($app-link-color);
        display: flex;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .cal-day-column {
      .cal-events-container {
        .cal-event {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 0px;
          background-color: #e3e6ef;
          border: none;
        }

        .sub-event {
          width: 42px !important;
          font-weight: bold;
        }

        .main-event {
          .cal-event-title {
            color: rgba($primary-color-dark);
            font-weight: bold;
          }

          &::before {
            content: "";
            position: absolute;
            background-color: rgb($stepper-blue);
            width: 4px;
            top: 0;
            bottom: 0;
            left: 0;
            height: 92%;
            margin-top: auto;
            margin-bottom: auto;
          }
        }
      }
    }

    .cell-lunch-break {
      &:after {
        position: absolute;
        content: attr(data-content);
        font-size: $font-size-root;
        font-weight: bold;
        color: rgb($stepper-blue);
        text-align: center;
        width: 47vw;
        z-index: 1;
        top: 23px;
      }
    }
  }

  .cal-hour-segment {
    border-bottom: 2px solid rgb($base-color-white) !important;
  }

  .cell-disabled {
    border: none;
    border-bottom: none !important;
    background-image: linear-gradient(29deg,
        rgb($secondary-page-background) 25%,
        rgb($base-color-white) 25%,
        rgb($base-color-white) 50%,
        rgb($secondary-page-background) 50%,
        rgb($secondary-page-background) 75%,
        rgb($base-color-white) 75%,
        rgb($base-color-white) 100%);
    background-size: 20.63px 11.43px;
  }

  .cell-highlight {
    position: relative;
    background-image: linear-gradient(29deg,
        #d5f6df 25%,
        rgb($base-color-white) 25%,
        rgb($base-color-white) 50%,
        #d5f6df 50%,
        #d5f6df 75%,
        rgb($base-color-white) 75%,
        rgb($base-color-white) 100%);
    background-size: 20.63px 11.43px;
    cursor: pointer;

    &.mr-0 {
      &::before {
        margin-right: 5px !important;
      }
    }

    &::after {
      position: absolute;
      background-color: rgb($app-link-color);
      width: 4px;
      top: 0;
      bottom: 0;
      left: 0;
      height: 92%;
      margin-top: auto;
      margin-bottom: auto;
    }

    // &:hover {
    //   &::before {
    //     content: "+";
    //     position: absolute;
    //     font-size: $font-size-root + 16;
    //     top: 0;
    //     bottom: 0;
    //     left: 0;
    //     right: 0;
    //     margin-top: auto;
    //     margin-bottom: auto;
    //     width: 20px;
    //     height: 20px;
    //     color: rgb($app-link-color);
    //     display: flex;
    //     align-items: center;
    //     margin-left: auto;
    //     margin-right: auto;
    //   }
    // }
  }

  .cell-error {
    position: relative;
    background-image: linear-gradient(29deg,
        #f3d6db 25%,
        rgb($base-color-white) 25%,
        rgb($base-color-white) 50%,
        #f3d6db 50%,
        #f3d6db 75%,
        rgb($base-color-white) 75%,
        #ffffff 100%);
    background-size: 20.63px 11.43px;

    &::after {
      position: absolute;
      background-color: rgb($error-color);
      width: 4px;
      top: 0;
      bottom: 0;
      left: 0;
      height: 92%;
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  .cal-time-label-column {
    border-right: 3px solid rgb($grey-shade-light);

    .cell-highlight,
    .cell-error {
      background-image: none;
      border-bottom: none !important;

      &::after {
        display: none;
      }
    }

    .cell-disabled {
      background: none;
    }
  }
}

/***************** Receptionist booking Calendar CSS END *****************/
button {
  white-space: pre-line !important;
}

.selectLabOption {
  height: auto !important;

  .mat-option-text {
    white-space: pre-line;
    line-height: 18px;
    overflow: unset;
    word-break: break-word;
    padding: 10px;
  }
}

.twolineText {
  .mat-option-text {
    white-space: pre-line;
    line-height: 18px;
    overflow: unset;
    word-break: break-word;
    padding: 10px 0px 10px 0px;
  }
}

.text-green {
  color: #24a148;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 962px) {
    padding: 5px;
    flex-direction: column;
    align-items: center;
  }
}

.text-red {
  color: #c33149 !important;
}

.background-warning {
  background: #ee7d1114 0% 0% no-repeat padding-box;
}

.background-normal {
  background: #8897be14 0% 0% no-repeat padding-box;
}