@import "../../../../src/styles/global/base/color";

.element-grid-row {
  position: relative;
}

.element-grid-row:not(.selected) {
  cursor: pointer;
}

.element-grid-row:not(.selected):hover {
  background-color: rgb($table-row-hover-color);
}

.element-grid-row.selected {
  border-bottom-width: 1px;
  border-bottom-color: rgba($grey-shade-light, 0.15);
  background-color: rgb($table-row-select-color);
}

.f-mat-table-header {
  display: flex;
  border-width: 0;
  border-bottom-width: 1px;
  border-top-width: 1px;
  border-style: solid;
  border-bottom-color: rgba($primary-color-light, 0.4);
  border-top-color: rgba($primary-color-light, 0.4);
  padding: 10px;
}

.mat-header-row {
  background: rgb($table-header-color);
}

.mat-header-cell,
.mat-header-cell * {
  text-align: left;
  font-family: $font-medium;
}

.mat-header-cell {
  font-size: $font-size-root;
}

// to handle mat-icons, only span with icons should be used - will remove once projects are migrated
.mat-header-cell .mat-icon {
  font-family: Material Icons, sans-serif;
}

.mat-sort-header-container {
  display: flex;
  cursor: pointer;
  font-family: $font-medium;
  color: rgb($grey-shade-dark);
  align-items: center;
  font-size: $font-size-root;
}

.f-right {
  margin-left: auto;
}

.mat-tbl-icon {
  width: 20px;
  margin-left: 10px;
  color: rgb($app-link-color) !important;

  &:disabled {
    span {
      &.mat-button-wrapper {
        span {
          opacity: 0.6;
        }
      }
    }
  }
}

.mat-row {
  padding: 15px 0px 5px 0px;
  align-items: flex-start;
  min-height: 35px;
}

.mat-table.input-min-spacing {
  .mat-form-field-infix {
    border: 0;
    padding-top: 0;
  }

  .mat-form-field {
    margin-bottom: 0;
  }
}

.table-flex {
  .mat-checkbox-layout {
    margin: 0;
  }

  .mat-header-row,
  .mat-row {
    min-height: 0;
  }

  .mat-row {
    padding: 0;
    min-height: 62px;
  }

  .mat-cell,
  .mat-header-cell {
    line-height: $table-cell-line-height;
    padding-top: $table-cell-padding-top;
    padding-bottom: $table-cell-padding-bottom;
    margin-right: $table-cell-regular-padding-right;
  }

  mat-cell:last-of-type,
  mat-footer-cell:last-of-type,
  mat-header-cell:last-of-type {
    margin-right: 0;
  }
}

.table-regular {
  .mat-checkbox-layout {
    margin: 0;
  }

  tr.mat-header-row,
  tr.mat-row {
    height: auto;
  }

  td.mat-cell,
  th.mat-header-cell {
    vertical-align: top;
    line-height: $table-cell-line-height;
    padding-top: $table-cell-padding-top;
    padding-bottom: $table-cell-padding-bottom;
    padding-right: $table-cell-regular-padding-right;
  }

  // expanded cell is td which contains entire child table(in tree structure)
  .expanded-cell.mat-cell {
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
  }
}

.edit-cell.mat-cell,
.table-regular .edit-cell.mat-cell {
  padding-bottom: $table-edit-cell-padding-bottom;
}

.icon-column.mat-header-cell,
.table-regular .icon-column.mat-header-cell {
  padding-top: 0;
  padding-bottom: 0;
}

.icon-column.mat-cell,
.table-regular .icon-column.mat-cell {
  padding-top: $table-icon-cell-padding-top;
  padding-bottom: 0;
}

.mat-cell {
  align-items: flex-start;
  line-height: 25px;
}

.mat-tbl-icon.mat-icon-button {
  height: 25px;
  width: 35px;
  line-height: 0px;
}

.comfortable {
  .table-flex {

    .mat-cell,
    .mat-header-cell {
      line-height: $table-cell-line-height;
      padding-top: 12px;
      padding-bottom: 12px;
      margin-right: $table-cell-regular-padding-right;
    }
  }

  .table-regular {

    td.mat-cell,
    th.mat-header-cell {
      vertical-align: top;
      line-height: $table-cell-line-height;
      padding-top: $table-cell-padding-top - 2px;
      padding-bottom: $table-cell-padding-bottom - 2px;
      padding-right: $table-cell-regular-padding-right;
    }

    .expanded-cell.mat-cell {
      padding-top: 0;
      padding-bottom: 0;
      padding-right: 0;
    }
  }

  .edit-cell.mat-cell,
  .table-regular .edit-cell.mat-cell {
    padding-bottom: $table-edit-cell-padding-bottom - 3px;
  }

  .icon-column.mat-header-cell,
  .table-regular .icon-column.mat-header-cell {
    padding-top: 0;
    padding-bottom: 0;
  }

  .icon-column.mat-cell,
  .table-regular .icon-column.mat-cell {
    padding-top: $table-icon-cell-padding-top - 4px;
    padding-bottom: 0;
  }
}

.compact {
  .table-flex {

    .mat-cell,
    .mat-header-cell {
      line-height: $table-cell-line-height;
      padding-top: 8px;
      padding-bottom: 8px;
      margin-right: $table-cell-regular-padding-right;
    }
  }

  .table-regular {

    td.mat-cell,
    th.mat-header-cell {
      vertical-align: top;
      line-height: $table-cell-line-height;
      padding-top: $table-cell-padding-top - 8px;
      padding-bottom: $table-cell-padding-bottom - 8px;
      padding-right: $table-cell-regular-padding-right;
    }

    .expanded-cell.mat-cell {
      padding-top: 0;
      padding-bottom: 0;
      padding-right: 0;
    }
  }

  .edit-cell.mat-cell,
  .table-regular .edit-cell.mat-cell {
    padding-bottom: $table-edit-cell-padding-bottom - 6px;
  }

  .icon-column.mat-header-cell,
  .table-regular .icon-column.mat-header-cell {
    padding-top: 0;
    padding-bottom: 0;
  }

  .icon-column.mat-cell,
  .table-regular .icon-column.mat-cell {
    padding-top: $table-icon-cell-padding-top - 9px;
    padding-bottom: 0;
  }
}

.column-text-border {
  border: 1px solid rgb($primary-color-dark);
  border-radius: 3px 3px 3px 3px;
  margin-right: 10px;
  margin-bottom: 10px;
  padding-right: 2px;
  padding-left: 2px;
}

mat-row:nth-child(odd) {
  background-color: rgb($table-row-color-1);
}

mat-row:nth-child(even) {
  background-color: rgb($table-row-color-2);
}

.grid-alternate-row-color:nth-child(4n-1) {
  background-color: rgb($table-row-color-1);
}

.grid-alternate-row-color:nth-child(4n-3) {
  background-color: rgb($table-row-color-2);
}

tbody tr.grid-detail-row td.mat-column-expandedDetailLevel1 {
  border: 0;
}

tbody tr.grid-detail-row td.mat-column-expandedDetailLevel1 td.mat-column-expandedDetailLevel2 {
  border: 0;
}

table {
  border-collapse: separate;
}

tr.grid-detail-row:nth-child(4n) {
  background-color: rgb($table-row-color-1);
}

tr.grid-detail-row:nth-child(4n-2) {
  background-color: rgb($table-row-color-2);
}

.grid-detail-row:nth-child(4n) .mat-column-expandedDetail .grid-element-detail .grid-detail-component table tbody tr {
  background-color: rgb($table-row-color-1);
}

.grid-detail-row:nth-child(4n-2) .mat-column-expandedDetail .grid-element-detail .grid-detail-component table tbody tr {
  background-color: rgb($table-row-color-2);
}

.pan-table-container {
  width: 100%;
  overflow-x: hidden;
}

.pan-table-container.active {
  cursor: pointer;
}

.pan-table {
  width: fit-content;
  min-width: 100%;

  .left-pan-control-cell.active {
    box-shadow: 5px 0px 10px -5px rgba(153, 153, 153, 0.8);
  }

  .right-pan-control-cell.active {
    box-shadow: -5px 0px 10px -5px rgba(153, 153, 153, 0.8);
  }

  .left-pan-control-cell.active #left-pan-btn,
  .right-pan-control-cell.active #right-pan-btn {
    visibility: visible;
  }

  #left-pan-btn,
  #right-pan-btn {
    visibility: hidden;
  }

  .mat-header-row,
  .mat-row {
    align-items: stretch;
  }

  #left-pan-btn .icon-UpArrow2,
  #right-pan-btn .icon-UpArrow {
    font-size: 28px;
  }

  #left-pan-btn {
    position: absolute;
    right: 0px;
  }

  #right-pan-btn {
    position: absolute;
    left: 0px;
  }
}

.pan-table-container.left .left-pan-cell.mat-cell {
  box-shadow: 3px 0px 3px 0px rgba(153, 153, 153, 0.3);
}

.pan-table-container.right .right-pan-cell.mat-cell {
  background: linear-gradient(to right,
      rgba($table-row-color-2, 0.1),
      rgba($table-row-color-2, 1),
      rgba($table-row-color-2, 1),
      rgba($table-row-color-2, 1));
}

.dark-theme {
  .mat-header-row {
    background: rgb($table-header-color);
  }

  .mat-paginator {
    background: rgb($base-color-white) !important;
  }

  .mat-header-cell {
    color: rgb($grey-shade-dark);
  }

  .pan-table {
    .left-pan-control-cell.active {
      box-shadow: 5px 0px 10px -5px rgba($shadow-color, 0.8);
    }

    .right-pan-control-cell.active {
      box-shadow: -5px 0px 10px -5px rgba($shadow-color, 0.8);
    }
  }

  .pan-table-container.left .left-pan-cell.mat-cell {
    box-shadow: 3px 0px 3px 0px rgba($shadow-color, 0.3);
  }
}


.main-container mat-cell:first-of-type,
.main-container mat-header-cell:first-of-type,
.main-container mat-footer-cell:first-of-type {
  padding-left: 32px;
}