@use '@angular/material' as mat;
@import "@angular/material/theming";
@include mat.core();

$bv-orange: (
  50: #ffffff,
  100: #fff7f4,
  200: #fecdbd,
  300: #fc9977,
  400: #fc8259,
  500: #f5822b,
  // $secondary-color-regular
  600: #fa551d,
  700: #f44205,
  800: #d63a04,
  900: #b83204,
  A100: #ffffff,
  A200: #fff7f4,
  A400: #fc8259,
  A700: #f44205,
  contrast: (50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: $black-87-opacity,
    A200: $black-87-opacity,
    A400: $black-87-opacity,
    A700: white)
);

$bv-blue: (
  50: #ffffff,
  100: #dde6f3,
  200: #b4c9e4,
  300: #7fa3d1,
  400: #6992c9,
  500: #0060f1,
  // $app-link-color
  600: #4072b4,
  700: #38649d,
  800: #305687,
  900: #284770,
  A100: #ffffff,
  A200: #dde6f3,
  A400: #6992c9,
  A700: #38649d,
  contrast: (50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: $black-87-opacity,
    A200: $black-87-opacity,
    A400: $black-87-opacity,
    A700: white)
);

$bv-orange-dark: (
  100: #fff7f4,
  500: #f5822b,
  // default color used by the theme
  700: #f44205,
  contrast: (100: $black-87-opacity,
    500: white,
    700: white)
);

$bv-blue-dark: (
  100: #dde6f3,
  500: #2ba3ff,
  // default color used by the theme ($secondary-color-regular)
  700: #38649d,
  contrast: (100: $black-87-opacity,
    500: white,
    700: white)
);

$candy-app-primary: mat-palette($bv-blue, 500);
$candy-app-accent: mat-palette($bv-orange, 500);
$candy-app-warn: mat-palette($mat-red);
$candy-app-theme: mat-light-theme($candy-app-primary, $candy-app-accent, $candy-app-warn);
@include angular-material-theme($candy-app-theme);

// Theme for Mat Table
$mat-light-theme-foreground: (
  divider: rgba(var(--primary-color-light), 0.4),
  secondary-text: rgb($grey-shade-dark),
  text: rgb($grey-shade-dark)
);
$mat-light-theme-background: (
  card: rgb($base-color-white)
);
$table-theme: (
  foreground: $mat-light-theme-foreground,
  background: $mat-light-theme-background
);
@include mat-table-theme($table-theme);

$ngx-app-primary: mat-palette($bv-orange, 500);
$ngx-app-accent: mat-palette($bv-blue, 500);
$ngx-app-theme: mat-light-theme($ngx-app-primary, $ngx-app-accent);
// @include mat-checkbox-theme($ngx-app-theme);
@include mat-radio-theme($ngx-app-theme);
@include mat-button-theme($ngx-app-theme);

$ngx-app-primary-dark: mat-palette($bv-orange-dark, 500);
$ngx-app-accent-dark: mat-palette($bv-blue-dark, 500);
$ngx-app-theme-dark: mat-light-theme($ngx-app-primary-dark, $ngx-app-accent-dark);

// .dark-theme {
//   $primary-dark: mat-palette($bv-blue-dark, 500);
//   $accent-dark: mat-palette($bv-orange, 500);
//   $warn-dark: mat-palette($mat-red);
//   // $theme-dark: mat-dark-theme($primary-dark, $accent-dark, $warn-dark);
//   $mat-dark-theme-foreground: (
//     base: white,
//     divider: $light-dividers,
//     dividers: $light-dividers,
//     disabled: $light-disabled-text,
//     disabled-button: rgba(white, 0.3),
//     disabled-text: $light-disabled-text,
//     elevation: black,
//     hint-text: $light-disabled-text,
//     secondary-text: rgb($grey-shade-medium),
//     icon: white,
//     icons: white,
//     text: rgb($grey-shade-dark),
//     slider-min: white,
//     slider-off: rgba(white, 0.3),
//     slider-off-active: rgba(white, 0.3)
//   );
//   $theme-dark: (
//     primary: $primary-dark,
//     accent: $accent-dark,
//     warn: $warn-dark,
//     is-dark: true,
//     foreground: $mat-dark-theme-foreground,
//     background: $mat-dark-theme-background
//   );

//   @include angular-material-theme($theme-dark);
//   @include mat-radio-theme($ngx-app-theme-dark);
//   @include mat-button-theme($ngx-app-theme-dark);
//   .mat-stepper-horizontal,
//   .mat-stepper-vertical {
//     background: rgb($base-color-white);
//   }
// }

// .dark-theme .mat-toolbar {
//   color: rgb($grey-shade-medium);
// }

// Define the theme for specific component.

.mat-drawer-container {
  color: rgb($grey-shade-dark);
}

.mat-button {
  padding: 0 6px 0 6px;
  margin: 6px 8px 6px 8px;
  min-width: 88px;
  border-radius: 3px;
  font-size: $font-size-root;
  text-align: center;
  // text-transform: uppercase;
  text-decoration: none;
  border: none;
  outline: none;
}

.mat-radio-button {
  //margin: 0 10px;
  margin: 0 40px 0 0;
}

.mat-raised-button {
  padding: 0 6px 0 6px;
  margin: 6px 8px 6px 8px;
  min-width: 88px;
  height: $mat-button-height;
  border-radius: 3px;
  font-size: $font-size-root;
  text-align: center;
  text-decoration: none;
  border: none;
  outline: none;
}

.mat-menu-item {
  font-size: $font-size-root;
}

.search-icon {
  font-size: 14px;
  color: rgb($primary-color-regular);
}

.cdk-overlay-dark-backdrop {
  background: rgba($grey-shade-light, 0.3);
}

.mat-drawer-backdrop.mat-drawer-shown {
  position: fixed;
  height: 100%;
  background: rgba($light-theme-blue, 0.6);
}

.mat-button-wrapper {
  font-family: $font-medium;
}

.mat-button:focus,
.mat-raised-button:focus {
  outline: none;
}

.mat-toolbar-row,
.mat-toolbar-single-row {
  padding: 0 6px;
  background-color: rgb($base-color-white);
  color: rgb($grey-shade-dark);
  border-bottom: none;
  box-shadow: 0px 2px 4px #99999966;
}

.mat-cell,
.mat-footer-cell {
  color: rgb($grey-shade-dark);
}

.mat-drawer.mat-drawer-end {
  background: rgba($base-color-white, 0.98);
}

// .mat-select-value-text {
//   padding-left: 10px;
// }

input.mat-input-element {
  margin-top: -0.0625em;
  text-overflow: ellipsis;
  color: rgb($grey-shade-dark);
}

.mat-form-field {
  .mat-focused {
    .mat-form-field-required-marker {
      background-color: #fff;
    }
  }
}

.mat-form-field .mat-form-field-label {
  color: rgb($grey-shade-medium);
  opacity: 0.88;

  .mat-form-field-required-marker {
    width: 20px;
    display: inline-flex;
    margin-left: 4px;
  }
}

.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.3em) scale(0.85) translateX(-10px);
}

.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill+.mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.3em) scale(0.85) perspective(100px) translateX(-10px);
}

.mat-form-field-label:not(.mat-form-field-empty) {
  // padding-left: 10px;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-empty.mat-form-field-label {
  // padding-left: 10px;
}

// .mat-form-field {
//   margin-bottom: 20px;
// }

.input-min-spacing .mat-form-field {
  margin-bottom: 24px;

  .mat-form-field-wrapper {
    padding-bottom: 0px;
  }

  .mat-form-field-underline {
    position: static;
  }

  .mat-form-field-subscript-wrapper {
    position: static;
    margin-top: 0;
    padding-left: 0;
  }

  .mat-error {
    font-size: 12px;
    margin-top: 5px;
  }
}

.mat-button.mat-primary .mat-button-focus-overlay,
.mat-icon-button.mat-primary .mat-button-focus-overlay,
.mat-stroked-button.mat-primary .mat-button-focus-overlay,
.mat-button.mat-accent .mat-button-focus-overlay,
.mat-icon-button.mat-accent .mat-button-focus-overlay {
  background: none;
}

.overlay-popup {
  border-radius: 3px;
}

.mat-form-field>.mat-form-field-wrapper>.mat-form-field-underline>.mat-form-field-ripple {
  height: 1px;
}

// switch button
.mat-slide-toggle>.mat-slide-toggle-label>.mat-slide-toggle-bar>.mat-slide-toggle-thumb-container>.mat-slide-toggle-thumb {
  height: 18px;
  width: 18px;
}

.mat-slide-toggle.mat-checked>.mat-slide-toggle-label>.mat-slide-toggle-bar>.mat-slide-toggle-thumb-container {
  transform: translate3d(24px, 0, 0);
}

.mat-slide-toggle>.mat-slide-toggle-label>.mat-slide-toggle-bar>.mat-slide-toggle-thumb-container {
  top: 3px;
  left: 3px;
}

.mat-slide-toggle>.mat-slide-toggle-label>.mat-slide-toggle-bar {
  width: 48px;
  height: 24px;
  border-radius: 12px;
}

.mat-slide-toggle-bar {
  background-color: rgb($grey-shade-light);
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: rgb($success-color);
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: rgb($base-color-white);
}

.mat-slide-toggle .mat-slide-toggle-thumb {
  background-color: rgb($base-color-white);
}

.mat-slide-toggle .mat-slide-toggle-ripple {
  visibility: hidden;
}

// Error Customization
.mat-form-field>.mat-form-field-wrapper>.mat-form-field-flex>.mat-form-field-infix>.mat-form-field-label-wrapper>.mat-form-field-label>.mat-form-field-required-marker {
  color: rgb($error-color);
}

.mat-error {
  //padding-left: 10px;
  color: rgb($error-color);
}

.mat-form-field.mat-form-field-invalid {
  .mat-form-field-label {
    color: rgb($error-color);

    &.mat-accent,
    .mat-form-field-required-marker {
      color: rgb($error-color);
    }
  }

  .mat-form-field-ripple,
  .mat-form-field-ripple.mat-accent {
    background-color: rgb($error-color);
  }
}

//Progress bar
.progress-bar {
  &.mat-progress-bar {
    height: 8px;
    border-radius: 5px;
  }
}

.mat-progress-bar .mat-progress-bar-element,
.mat-progress-bar .mat-progress-bar-fill::after {
  border-radius: 5px;
}

.mat-progress-bar .mat-progress-bar-fill::after {
  margin: 1px;
  height: 6px !important;
}

//dropdown customization

.mat-option.mat-active {
  background: none;
  color: rgb($app-link-color);
}

.mat-select-panel .mat-option:hover {
  color: rgb($app-link-color);
  background-color: rgb($base-color-white);
}

.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: none;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: rgb($app-link-color);
  border-left: 2px solid rgb($app-link-color);
}

.mat-select-panel .mat-optgroup-label,
.mat-select-panel .mat-option {
  margin-left: 15px;
}

.mat-autocomplete-panel .mat-option:hover {
  color: rgb($app-link-color);
  background-color: rgb($base-color-white);
}

.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: none;
}

.mat-select-panel {
  margin-top: 30px;
  margin-left: 6px;
  margin-bottom: 40px;
}

.cdk-overlay-pane .mat-select-panel {
  padding-top: 10px;
  padding-bottom: 10px;
}

.cdk-overlay-pane.mat-autocomplete-panel-above {
  margin-bottom: 10px;
}

.mat-sidenav-content {
  margin-left: 0px !important;
}

.mat-sidenav {
  position: fixed !important;
}

.customWidthDropdown {
  min-width: calc(100% + 20px) !important
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background: rgb($app-link-color);
}