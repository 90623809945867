@import "../../../../src/styles/global/base/color";

.mat-tab-header {
  display: flex;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
}

.mat-tab-labels {
  justify-content: center;
}

.mat-tab-label {
  color: rgb($app-link-color);
  margin: 0px 15px;
}

.mat-tab-label-content {
  font-size: $tab-font-size;
}

.mat-tab-labels>.mat-tab-label {
  opacity: 1;
  min-width: 0px;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: none !important;
}

.mat-tab-label.mat-tab-label-active {
  color: rgb($grey-shade-dark);
  font-weight: 100;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: rgb($app-link-color);
}