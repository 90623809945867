@mixin font-size-setter($data) {
  html * {
    font-size: map-get($data, root-fs);
  }

  .group-title {
    font-size: map-get($data, root-fs);
  }

  .group-title-res {
    font-size: map-get($data, root-fs);
  }

  .sec-title {
    font-size: map-get($data, section-title-fs);
  }

  .mat-tab-label-content {
    font-size: map-get($data, tab-fs);
  }

  .mat-header-cell,
  .mat-sort-header-container {
    font-size: map-get($data, root-fs);
  }

  .mat-cell,
  .mat-footer-cell {
    font-size: map-get($data, root-fs);
  }

  .mat-menu-item {
    font-size: map-get($data, root-fs);
  }

  [class^="icon-"],
  [class*=" icon-"] {
    font-size: map-get($data, icon-fs);
  }

  h1 {
    font-size: map-get($data, h1-fs);
  }

  h2 {
    font-size: map-get($data, h2-fs);
  }

  h3 {
    font-size: map-get($data, h3-fs);
  }

  h4 {
    font-size: map-get($data, h4-fs);
  }

  h5 {
    font-size: map-get($data, h5-fs);
  }

  h6 {
    font-size: map-get($data, h6-fs);
  }

  .mat-menu-tag-close {
    font-size: 10px;
  }

  .mat-menu-tag-tick {
    font-size: 7px;
  }

  .circle {
    font-size: map-get($data, root-fs);
  }
}

@mixin container-spacing($data) {
  .main-container-res {
    padding: map-get($data, main-container-padding);
  }

  .left-column {
    padding: map-get($data, main-container-padding);
    padding-right: 0px;
    width: map-get($data, left-column-width);
  }

  .sec-title-line {
    margin-bottom: map-get($data, section-title-margin);
  }

  .slider-data-container {
    padding: map-get($data, slider-container-margin);
  }

  .f-mat-table-header {
    padding: map-get($data, table-header-padding);
  }

  .two-col-container {
    grid-template-columns: minmax(map-get($data, left-column-width), 10%) auto;
  }

  .launcher-tab-margin {
    margin-top: map-get($data, launcher-tab-margin);
  }

  .launcher-icon-margin {
    margin-top: map-get($data, launcher-icon-margin);
  }
}

@mixin table-settings($data) {
  .table-flex {
    .mat-cell,
    .mat-header-cell {
      line-height: map-get($data, line-height);
      padding-top: map-get($data, table-cell-padding-top);
      padding-bottom: map-get($data, table-cell-padding-bottom);
    }
  }

  .table-regular {
    td.mat-cell,
    th.mat-header-cell {
      line-height: map-get($data, line-height);
      padding-top: map-get($data, table-cell-padding-top);
      padding-bottom: map-get($data, table-cell-padding-bottom);
    }
  }

  .icon-column.mat-cell,
  .table-regular .icon-column.mat-cell {
    padding-top: map-get($data, table-icon-cell-padding-top);
    padding-bottom: 0;
  }

  .edit-cell.mat-cell,
  .table-regular .edit-cell.mat-cell {
    padding-bottom: map-get($data, table-edit-cell-padding-bottom);
  }

  .icon-column.mat-header-cell,
  .table-regular .icon-column.mat-header-cell {
    padding-top: 0;
    padding-bottom: 0;
  }
}

@mixin components-sizing($data) {
  .mat-raised-button {
    height: map-get($map: $data, $key: button-height);
  }

  .circle {
    height: map-get($data, css-icon-size);
    width: map-get($data, css-icon-size);
    min-height: map-get($data, css-icon-size);
    min-width: map-get($data, css-icon-size);
    line-height: map-get($data, css-icon-size);
  }
}

@mixin responsive-setter($data) {
  $breakpoint: map-get($data, break-point);

  @media screen and (max-width: $breakpoint) {
    $fs-map: map-get($data, fs-map);
    $spacing-map: map-get($data, spacing-map);
    $table-data: map-get($data, table-data);
    $component-data: map-get($data, component-data);

    @if ($fs-map) {
      @include font-size-setter($fs-map);
    }
    @if ($spacing-map) {
      @include container-spacing($spacing-map);
    }
    @if ($table-data) {
      @include table-settings($table-data);
    }
    @if ($table-data) {
      @include components-sizing($component-data);
    }
  }
}

$fs-config: (
  root-fs: $font-size-root - 2px,
  tab-fs: $tab-font-size - 4px,
  section-title-fs: $section-title-font-size - 2px,
  icon-fs: $icon-font-size - 2px,
  h1-fs: $font-size-root + 8px,
  h2-fs: $font-size-root + 6px,
  h3-fs: $font-size-root + 4px,
  h4-fs: $font-size-root + 2px,
  h5-fs: $font-size-root,
  h6-fs: $font-size-root - 2px
);

$spacing-config: (
  main-container-padding: $main-container-padding - 5px,
  section-title-margin: $section-title-margin - 5px,
  slider-container-margin: $slider-container-margin - 10px,
  table-header-padding: $table-header-padding - 5px,
  left-column-width: $left-column-width - 95px,
  launcher-tab-margin: $launcher-tab-margin - 30px,
  launcher-icon-margin: $launcher-icon-margin - 15px
);

$table-config: (
  line-height: $table-cell-line-height - 2px,
  table-cell-padding-top: $table-cell-padding-top - 4px,
  table-cell-padding-bottom: $table-cell-padding-bottom - 6px,
  table-edit-cell-padding-bottom: $table-edit-cell-padding-bottom - 2px,
  table-icon-cell-padding-top: $table-icon-cell-padding-top - 5px
);

$components-config: (
  button-height: $mat-button-height - 3px,
  css-icon-size: $css-icon-size - 10px
);

$data: (
  break-point: 1440px,
  fs-map: $fs-config,
  spacing-map: $spacing-config,
  table-data: $table-config,
  component-data: $components-config
);

@include responsive-setter($data);
